<template>
  <div
    class="public-label"
    :class="[
      className,
      `is-${shape}`,
      {'is-clickable': clickable,
       'is-actived': actived,
       'is-closable': closable}
    ]"
    @click="handleLabelClick($event)"
  >
    <p class="label-text">
      <slot v-if="$slots.default"></slot>
      <template v-else>{{ title }}</template>
    </p>
    <span
      v-if="closable"
      ref="close"
      class="label-close"
      @click.prevent="handleCloseClick"
    >
      <slot v-if="$slots.close"></slot>
      <i class="iconfont icon-a-icon-delete1x close-btn"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'PublicLabel',
  props: {
    // 形状 默认方，可选两侧圆
    shape: {
      type: String,
      default: 'square',
      validator: function(v) {
        return ['round', 'square'].includes(v)
      }
    },
    // 可配置类名
    className: {
      type: [String, Object],
      default: ''
    },
    // 标签名
    title: {
      type: String,
      default: ''
    },
    clickable: {
      type: Boolean,
      default: false
    },
    // 是否是选中状态
    actived: {
      type: Boolean,
      default: false
    },
    // 是否可删除
    closable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // 点击标签
    handleLabelClick(e) {
      if (this.closable && this.$refs.close?.contains(e.target)) return false
      this.$emit('click')
    },
    // 关闭点击
    handleCloseClick() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.public-label {
  position: relative;
  display: inline-block;
  cursor: default;
  &.is-round {
    padding: 4px 24px;
    border-radius: 14px;
    border: 1px solid #DCDFE6;
    .label-text {
      font-size: 16px;
    }
  }
  &.is-square {
    padding: 4px 8px;
    border-radius: 2px;
    border: 1px solid #E6E6E6;
  }
  &.is-clickable {
    cursor: pointer;
  }
  &.is-actived {
    border-color: #319BD8 !important;
    .label-text {
      color: @theme !important;
    }
  }
  &.is-closable {
    &:hover {
      .label-close {
        opacity: 1;
        user-select: unset;
        pointer-events: unset;
      }
    }
  }
  .label-text {
    font-size: 14px;
    color: #606266;
    line-height: 17px;
    user-select: none;
  }
  .label-close {
    opacity: 1;
    user-select: none;
    pointer-events: none;
    .transition();
    .close-btn {
      position: absolute;
      top: -10px;
      right: -11px;
      display: block;
      font-size: 24px;
      line-height: 24px;
      color: #c8161d;
      cursor: pointer;
      .transition();
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
  @media screen and (max-width: 768px) {
    .label-text {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
