const cubic = value => Math.pow(value, 3)
const easeInOutCubic = value => value < 0.5
  ? cubic(value * 2) / 2
  : 1 - cubic((1 - value) * 2) / 2

// 从当前高度滚动到具体的高度
export const scrollToTop = (endValue = 0) => {
  const el = document.documentElement
  const beginTime = Date.now()
  const beginValue = el.scrollTop
  const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16))
  const frameFunc = () => {
    const progress = (Date.now() - beginTime) / 500
    if (progress < 1) {
      el.scrollTop = beginValue + (endValue - beginValue) * easeInOutCubic(progress)
      rAF(frameFunc)
    } else {
      el.scrollTop = endValue
    }
  }
  rAF(frameFunc)
}

// 计算某元素距顶部的距离
export const calculateTop = (node) => {
  var top = node.offsetTop
  var parent = node.offsetParent
  while (parent != null) {
    top += parent.offsetTop
    parent = parent.offsetParent
  }
  return top
}
