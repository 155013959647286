// 个人中心状态图标 与 名称
export const STATUS = Object.freeze({
  1: {
    name: 'Published',
    icon: 'iconfont icon-a-icon-yifabu1x'
  },
  2: {
    name: 'Reviewing',
    icon: 'iconfont icon-a-icon-shenhezhong1x'
  },
  3: {
    name: 'Unpublished',
    icon: 'iconfont icon-a-icon-weifabu1x'
  },
  // 4: {
  //   name: 'Unpublished',
  //   icon: 'iconfont icon-a-icon-weifabu1x'
  // },
  4: {
    name: 'Preservation',
    icon: 'iconfont icon-a-icon-weifabu1x'
  },
  5: {
    name: 'Not Republished After Update',
    icon: 'iconfont icon-a-icon-weifabu1x'
  }
})

// 上传文件类型
export const accept = Object.freeze({
  jpg: ['image/jpg', 'image/jpeg'],
  jpeg: ['image/jpg', 'image/jpeg'],
  png: ['image/png']
})
