<template>
  <div class="my-profile">
    <div class="profile-content container">
      <p class="title">{{ LP.lang_my_profile	}}</p>
      <div class="form-box">
        <div class="profile-item-wraper clearfix" :class="{'disabled-style' : isDisabled}">
          <section class="item-name">{{ LP.lang_dipp_company_name	}}</section>
          <section class="item-value">
            <input
              v-model="myProfile.companyName"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="isDisabled"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix" :class="{'disabled-style' : isDisabled}">
          <section class="item-name">{{ LP.lang_company_url }}</section>
          <section class="item-value">
            <input
              v-model="myProfile.companyUrl"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="isDisabled"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix" :class="{'disabled-style' : isDisabled}">
          <section class="item-name">{{ LP.lang_zip_postal_code }}</section>
          <section class="item-value">
            <input
              v-model="myProfile.zip"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="isDisabled"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix disabled-style">
          <section class="item-name">{{ LP.lang_city_town }}</section>
          <section class="item-value">
            <input
              v-model="myProfile.city"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="true"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix disabled-style">
          <section class="item-name">{{ LP.lang_state_province	}}</section>
          <section class="item-value">
            <input
              v-model="myProfile.state"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="true"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix disabled-style">
          <section class="item-name">{{ LP.lang_country_region }}</section>
          <section class="item-value">
            <input
              v-model="myProfile.country"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="true"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix" :class="{'disabled-style' : isDisabled}">
          <section class="item-name">{{ LP.lang_first_name }}</section>
          <section class="item-value">
            <input
              v-model="myProfile.firstName"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="isDisabled"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix" :class="{'disabled-style' : isDisabled}">
          <section class="item-name">{{ LP.lang_last_name }}</section>
          <section class="item-value">
            <input
              v-model="myProfile.lastName"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="isDisabled"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix" :class="{'disabled-style' : isDisabled}">
          <section class="item-name">{{ LP.lang_position_Title }}</section>
          <section class="item-value">
            <input
              ref="positionInput"
              v-model="myProfile.position"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="isDisabled"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix" :class="{'disabled-style' : isDisabled}">
          <section class="item-name">{{ LP.lang_phone_number }}</section>
          <section class="item-value">
            <input
              id="phone"
              ref="phoneInput"
              v-model="myProfile.phoneNumber"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="isDisabled"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix" :class="{'disabled-style' : isDisabled}">
          <section class="item-name">{{ LP.lang_company_description }}</section>
          <section class="item-value">
            <input
              v-model="myProfile.companyDescription"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="isDisabled"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix disabled-style">
          <section class="item-name">{{ LP.lang_number_of_employees }}</section>
          <section class="item-value">
            <input
              v-model="myProfile.employees"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="true"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix disabled-style">
          <section class="item-name">{{ LP.lang_main_business_areas }}</section>
          <section class="item-value">
            <input
              v-model="myProfile.businessAreas"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="true"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix disabled-style">
          <section class="item-name">{{ LP.lang_industries_of_your_company	}}</section>
          <section class="item-value">
            <input
              v-model="myProfile.companyIndustries"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="true"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix" :class="{'disabled-style' : isDisabled}">
          <section class="item-name">
            {{ LP.lang_focus_products }}
          </section>
          <section class="item-value">
            <input
              v-model="myProfile.productsInFocus"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="isDisabled"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix disabled-style">
          <section class="item-name">{{ LP.lang_dipp_partner_level }}</section>
          <section class="item-value">
            <input
              v-model="myProfile.partnerLevel"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="true"
            />
          </section>
        </div>
        <div class="profile-item-wraper clearfix disabled-style">
          <section class="item-name">{{ LP.lang_dhop_developer	}}</section>
          <section class="item-value">
            <input
              v-model="myProfile.dhop_state"
              class="item-value-input"
              type="text"
              autocomplete="off"
              :disabled="true"
            />
          </section>
        </div>
      </div>
      <div class="profile-btn-wrap">
        <button class="cancel" @click="clickCancel()">{{ LP.lang_cancel }}</button>
        <button class="submit" @click="submitInfo()">{{ LP.lang_determine }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { userInfoUpdateApi } from '@/api/myPage'
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      myProfile: state => state.myPage.userInfo // 用户信息
    }),
    // 是否禁用输入框
    isDisabled() {
      return this.myProfile && this.myProfile.is_show === '1'
    }
  },
  watch: {
    myProfile(val) {
      if (Object.keys(val).length) {
        if (val.businessAreas.length > 0) {
          let businessAreasTag = ''
          val.businessAreas.forEach(item => {
            businessAreasTag += item + ';'
          })
          val.businessAreas = businessAreasTag
        }
        if (val.companyIndustries.length > 0) {
          let companyIndustriesTag = ''
          val.companyIndustries.forEach(item => {
            companyIndustriesTag += item + ';'
          })
          val.companyIndustries = companyIndustriesTag
        }
      }
    },
    immediate: true,
    deep: true
  },
  created() {
    this.$store.dispatch('myPage/getUserInfo') // 获取用户信息
  },
  methods: {
    // 返回
    clickCancel() {
      this.$router.push('/mypage')
    },
    // 提交表单
    submitInfo() {
      console.log(this.myProfile)
      userInfoUpdateApi({
        companyName: this.myProfile.companyName,
        companyUrl: this.myProfile.companyUrl,
        zip: this.myProfile.zip,
        firstName: this.myProfile.firstName,
        lastName: this.myProfile.lastName,
        email: this.myProfile.emailAddress,
        position: this.myProfile.position,
        phoneNumber: this.myProfile.phoneNumber,
        companyDescription: this.myProfile.companyDescription,
        productsInFocus: this.myProfile.productsInFocus
      })
        .then(() => {
          this.$message.success('Submit success')
        })
        .catch(() => {
          this.$message.error('Submit failed')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.my-profile {
  .profile-content {
    background: #fff;
    padding: 68px 0 98px 0;
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    .profile-item-wraper {
      position: relative;
      margin-top: 20px;
      border: solid 1px #dadada;
      line-height: 40px;
      &.disabled-style {
        background: #f5f5f5;
      }
      &.edit {
        background: #fff;
      }
      .item-name {
        padding-left: 18px;
        float: left;
        width: 300px;
        border-right: solid 1px #dadada;
        font-weight: 500;
        font-size: 14px;
        background: #fff;
      }
      .item-value {
        padding-right: 60px;
        padding-left: 18px;
        font-size: 16px;
        overflow: hidden;
        color: #666666;
        font-weight: 600;
        .item-value-input {
          appearance: none;
          font-size: 16px;
          overflow: hidden;
          width: 100%;
          color: #666666;
          font-weight: 600;
          outline: none;
          border: 0px;
          font: inherit;
          background: transparent;
        }
      }
      .item-svg {
        position: absolute;
        right: 27px;
        top: 2px;
        .profile-icon {
          width: 18px;
          height: 20px;
          fill: #999999;
          cursor: pointer;
        }
      }
    }
    .profile-btn-wrap {
      margin-top: 50px;
      text-align: center;
      button {
        width: 145px;
        height: 30px;
        border-radius: 30px;
        background-color: #dadada;
        cursor: pointer;
        transition: all 0.6s;
        &:hover {
          opacity: 0.8;
        }
      }
      .cancel {
        color: #666666;
        margin-right: 145px;
      }
      .submit {
        color: #fff;
        background-color: #0e5fac;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .profile-content {
      padding: 24px 0 40px 0;
      .form-box {
        overflow-x: auto;
        .profile-item-wraper {
          min-width: 768px;
        }
      }
      .title {
        margin-bottom: 16px;
      }
      .profile-item-wraper {
        margin-top: 12px;
        .item-name {
          padding-left: 12px;
        }
      }
      .profile-btn-wrap {
        button {
          width: 100px;
        }
        .cancel {
          margin-right: 18px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.myProfile {
  .el-input__inner {
    border: none;
    width: 542px;
    border-radius: 0;
  }
}
</style>
