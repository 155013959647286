<template>
  <div class="container review">
    <div class="back-wrap">
      <router-link class="back" to="/mypage">{{ LP.lang_back_to_my_page }}</router-link>
    </div>
    <div class="inner">
      <h3 class="title">{{ LP.lang_in_review }}</h3>
      <p class="desc">
        {{ LP.lang_in_review_description }} <a href="mailto:Ecosystem@dahuatech.com">{{ LP.lang_depp_email }}</a>
      </p>
      <img class="img" src="@/assets/img/myPage/inReview.png" alt="In Review" />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.review {
  padding-top: 28px;
  padding-bottom: 72px;
  .back-wrap {
    margin-bottom: 28px;
    .back {
      font-size: 16px;
      color: #333333;
      line-height: 21px;
    }
  }
  .inner {
    width: 936px;
    margin: auto;
    max-width: 100%;
    margin-top: 42px;
    overflow: hidden;
    text-align: center;
    .title {
      font-size: 24px;
      font-weight: 600;
      color: #303133;
      line-height: 32px;
      text-align: center;
      margin-bottom: 24px;
    }
    .desc {
      font-size: 18px;
      color: #606266;
      line-height: 24px;
      text-align: start;
      a {
        color: @theme;
        text-decoration: underline;
      }
    }
    .img {
      width: 360px;
    }
  }
  @media screen and (max-width: 768px) {
    padding-top: 15px;
    padding-bottom: 50px;
    .back-wrap {
      margin-bottom: 15px;
      .back {
        font-size: 14px;
      }
    }
    .inner {
      margin-top: 20px;
      .title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
      }
      .desc {
        font-size: 14px;
        line-height: 22px;
      }
      .img {
        width: 250px;
      }
    }
  }
}
</style>
