import axios from 'axios'
const CancelToken = axios.CancelToken

export default {
  data() {
    return {
      cancel: null
    }
  },
  methods: {
    // 存储当前请求
    cancelToken() {
      return new CancelToken(c => {
        this.cancel = c
      })
    },
    // 请求中断
    cancelRequest() {
      if (this.cancel) this.cancel()
    }
  }
}
