<template>
  <div class="my-list-container">
    <div v-if="title" class="name-wrap">
      <h5 class="name" v-html="title"></h5>
      <div v-if="showTitleBtn" class="btns" @click="btnClick">
        <i :class="titleBtnConfig.icon"></i>
        <span class="add" v-html="titleBtnConfig.text"></span>
      </div>
      <slot name="title-btns"></slot>
    </div>
    <ul class="module-content-wrap" :class="{'no-data': !data.length}">
      <li v-for="(item, index) in data" :key="index" class="item">
        <div class="left-part ellipsis">
          <span class="item-name ellipsis" v-html="item[parameter.name]"></span>
          <div v-if="Object.prototype.hasOwnProperty.call(item, 'type')" class="item-status">
            <i :class="status[item[parameter.type]].icon"></i>
            <span class="status-name">{{ status[item[parameter.type]].name }}</span>
          </div>
        </div>
        <div class="item-button-wrap">
          <template v-if="!!rowBtns.length">
            <el-button
              v-for="(btn, btnIndex) in rowBtns"
              :key="btnIndex"
              class="item-button"
              @click="rowBtnClick(btn.btn, { item, index})"
            >{{ btn.name }}</el-button>

          </template>
          <slot name="row-btns"></slot>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { STATUS } from '@/constant.js'
export default {
  props: {
    // 是否展示标题，默认展示
    title: {
      type: String,
      default: ''
    },
    // 是否展示按钮
    showTitleBtn: {
      type: Boolean,
      default: false
    },
    // 单独按钮文案与图标
    titleBtnConfig: {
      type: Object,
      default: function() { // 这里如果写成箭头函数，下面的this指向会报错
        return {
          icon: 'iconfont icon-a-icon-Add1x',
          text: this.LP.lang_add
        }
      }
    },
    // 列表循环数据
    data: {
      type: Array,
      default: () => []
    },
    // 列表显示字段配置 name是行内容显示名，type图标类型
    // 1Published 2Reviewing 4Unpublished 5Not Republished After Update
    parameter: {
      type: Object,
      default: () => ({
        name: 'name',
        type: 'type'
      })
    },
    // 列表按钮配置 name是按钮显示名，btn是点击事件名
    rowBtns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      status: STATUS
    }
  },
  methods: {
    // 标题处按钮点击
    btnClick() {
      this.$emit('btnClick')
    },
    // 行按钮点击, rowInfo包含当前行item，行索引index
    rowBtnClick(btnName, rowInfo) {
      this.$emit(btnName, rowInfo)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.my-list-container {
  & + .my-list-container {
    margin-top: 32px;
  }
  .name-wrap {
    border-bottom: 1px solid #E4E7ED;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 24px;
      font-weight: 600;
      color: #303133;
      line-height: 32px;
      padding-bottom: 16px;
    }
    .btns {
      color: @theme;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .transition();
      &:hover {
        .iconfont {
          transform: rotate(180deg);
        }
      }
      .iconfont {
        font-size: 24px;
        .transition();
      }
      .add {
        font-size: 16px;
        padding-left: 6px;
      }
    }
  }
  .module-content-wrap {
    &.no-data {
      height: 20px;
    }
    .item {
      padding: 16px 48px;
      background: #F5F7FA;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .transition();
      & + .item {
        margin-top: 16px;
      }
      &:hover {
        box-shadow: 0px 3px 8px 0px rgba(173, 173, 173, 0.38);
      }
      .left-part {
        display: flex;
        align-items: center;
        .item-name {
          font-size: 18px;
          font-weight: 600;
          color: #303133;
          line-height: 40px;
        }
        .item-status {
          flex-shrink: 0;
          margin-left: 24px;
          display: flex;
          align-items: center;
          .iconfont {
            font-size: 24px;
          }
          .status-name {
            padding-left: 4px;
            font-size: 14px;
            color: #333333;
          }
        }
      }
      .item-button-wrap {
        flex-shrink: 0;
        // #909399
        .item-button {
          height: 40px;
          border-color: @theme;
          background: @theme;
          border-radius: 4px;
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 22px;
          padding: 9px 20px;
          margin-left: 16px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
