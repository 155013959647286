<template>
  <div
    v-loading="pageLoading"
    :element-loading-text="pageLoadingText"
    class="container solution-edit-page"
  >
    <page-top
      editable
      :title="form.solution_name"
      :status="audit_status"
      @change="handleNameChange"
      @save="handleSave"
    />
    <div class="solution-inner">
      <div class="anchor-sider-wrap">
        <affix relative-element-selector="#right" :offset="{top: 90, bottom: 0}">
          <ul class="anchor">
            <li
              v-for="(anchorItem, index) in anchor"
              :key="index"
              class="anchor-item"
              @click="scroll(anchorItem.anchor)"
            >
              <span
                :class="[
                  'number',
                  {'isValid': Object.keys(anchorItem.validProps).every(i => !!anchorItem.validProps[i])}
                ]"
              >{{ index + 1 }}</span>
              <span class="name">{{ anchorItem.name }}</span>
            </li>
          </ul>
        </affix>
      </div>
      <div id="right" class="form-wrap">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-position="top"
          @validate="listenValidate"
        >
          <div id="basicInfo" class="form-part-wrap">
            <h4 class="form-part-title">
              {{ LP.lang_basic_info	}}<span class="sub">{{ LP.lang_required }}</span>
            </h4>
            <el-form-item class="half-position 1001" prop="cover_image">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_cover_image }}</span>
                </p>
              </template>
              <cropper-upload v-model="form.cover_image" class-name="common-height" />
            </el-form-item>
            <el-form-item class="half-position 1002" prop="short_intro">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_short_intro	}}</span>
                  <span class="text-len">{{ form.short_intro.length }}/500</span>
                </p>
              </template>
              <el-input
                v-model="form.short_intro"
                resize="none"
                class="common-height"
                type="textarea"
                :maxlength="500"
              />
            </el-form-item>
            <el-form-item class="half-position 1003" prop="technical_email">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_technical_email }}</span>
                </p>
              </template>
              <el-input
                v-model="form.technical_email"
                clearable
              />
            </el-form-item>
            <el-form-item class="half-position 1004" prop="sales_email">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_sales_email }}</span>
                </p>
              </template>
              <el-input
                v-model="form.sales_email"
                clearable
              />
            </el-form-item>
            <el-form-item ref="ElFormItemSolutionType" class="half-position 1005" prop="solution_type">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_solution_type	}}</span>
                </p>
              </template>
              <el-select
                v-model="form.solution_type"
                :placeholder="LP.lang_select"
                multiple
                clearable
                @change="handleSelectEmit('ElFormItemSolutionType')"
              >
                <el-option
                  v-for="(item, index) in solutionTypeOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </div>
          <div id="solutionDescription" class="form-part-wrap">
            <h4 class="form-part-title">{{ LP.lang_solution_description	}}<span class="sub">{{ LP.lang_required }}</span></h4>
            <el-form-item class="half-position 1006" prop="solution_image">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_solution_image }}</span>
                </p>
              </template>
              <cropper-upload v-model="form.solution_image" class-name="common-height" />
            </el-form-item>
            <el-form-item class="half-position 1007" prop="solution_description">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_description }}</span>
                  <span class="text-len">{{ form.solution_description.length }}/500</span>
                </p>
              </template>
              <el-input
                v-model="form.solution_description"
                resize="none"
                class="common-height"
                type="textarea"
                :maxlength="500"
              />
            </el-form-item>
          </div>
          <div id="interoperability" class="form-part-wrap">
            <h4 class="form-part-title">{{ LP.lang_interoperability }}<span class="sub">{{ LP.lang_required }}</span></h4>
            <el-form-item ref="ElFormItemDahua" class="half-position grey-bg interoperability 1008" prop="dahua_product_icon">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_supported_dahua_products }}</span>
                </p>
              </template>
              <el-select
                v-model="form.dahua_product_icon"
                class="vertical-spacing"
                :placeholder="LP.lang_select"
                clearable
                multiple
                @change="handleSelectEmit('ElFormItemDahua')"
              >
                <el-option
                  v-for="(item, index) in dahuaIconOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-input
                v-model="form.dahua_product_description"
                resize="none"
                class="common-height vertical-spacing is-not-error"
                type="textarea"
                :placeholder="LP.lang_license_information"
              />
            </el-form-item>
            <el-form-item ref="ElFormItemPartner" class="half-position grey-bg interoperability 1009" prop="partner_product_icon">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_supported_partner_products }}</span>
                </p>
              </template>
              <div
                v-for="(item, index) in form.partner_product_icon"
                :key="index"
                class="vertical-spacing"
              >
                <el-input
                  v-model="item.product_name"
                  class="half-position-tiny"
                  :class="{'is-not-error': !!item.product_name || (!item.product_name && !item.icon && !form.partner_product_icon.every(i => !i.product_name && !i.icon))}"
                  :placeholder="LP.lang_product_name"
                />
                <el-select
                  v-model="item.icon"
                  class="half-position-tiny"
                  :class="{'is-not-error': !!item.icon || (!item.product_name && !item.icon && !form.partner_product_icon.every(i => !i.product_name && !i.icon))}"
                  :placeholder="LP.lang_select_icon"
                  clearable
                  @change="handleSelectEmit('ElFormItemPartner')"
                >
                  <el-option
                    v-for="(item, index) in partnerIconOptions"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <el-input
                v-model="form.partner_product_description"
                resize="none"
                class="common-height vertical-spacing is-not-error"
                type="textarea"
                :placeholder="LP.lang_license_information"
              />
            </el-form-item>
          </div>
          <div id="keyFeatures" class="form-part-wrap">
            <h4 class="form-part-title">
              {{ LP.lang_key_features	}}<span class="sub">{{ LP.lang_required }}</span>
              <div class="form-part-add" @click="handleKeyAdd">
                <i class="iconfont icon-a-icon-Add1x"></i>
                <span class="add">{{ LP.lang_add }}</span>
              </div>
            </h4>
            <el-form-item ref="ElFormItemKey" class="full-position grey-bg 1010" prop="key_features">
              <el-input
                v-for="(item, index) in form.key_features"
                :key="index"
                v-model="form.key_features[index].name"
                class="vertical-spacing"
                :placeholder="LP.lang_feature"
              >
                <span
                  slot="append"
                  class="iconfont icon-a-icon-delete1x delete-btn"
                  @click="handleKeyDelete(index)"
                ></span>
              </el-input>
            </el-form-item>
          </div>
          <div id="howItWorks" class="form-part-wrap">
            <!-- <h4 class="form-part-title">{{ LP.lang_how_it_works }}<span class="sub">{{ LP.lang_required }}</span></h4> -->
            <h4 class="form-part-title">{{ LP.lang_how_it_works }}</h4>
            <p class="form-part-subtitle">
              <span class="item">{{ LP.lang_image_message }}</span>
              <span class="item">{{ LP.lang_video_message	}}</span>
            </p>
            <el-form-item class="full-position grey-bg 1011" prop="how_works">
              <div
                v-for="(item, index) in form.how_works"
                :key="index"
                class="vertical-spacing how-it-work-spacing"
              >
                <cropper-upload
                  v-model="item.image"
                  :class-name="[
                    'common-height',
                    'half-position-tiny',
                    {'is-not-error': !!item.image || (!item.image && !item.title && form.how_works.some(i => !!i.title || !!i.image))}
                  ]"
                />
                <el-input
                  v-model="item.title"
                  resize="none"
                  class="common-height half-position-tiny-18"
                  :class="{'is-not-error': !!item.image || !!item.title || (!item.image && !item.title && form.how_works.some(i => !!i.title || !!i.image))}"
                  type="textarea"
                  :placeholder="LP.lang_video_link"
                />
              </div>
            </el-form-item>
          </div>
          <div id="infoCenter" class="form-part-wrap">
            <h4 class="form-part-title">
              {{ LP.lang_info_center }}<span class="sub">{{ LP.lang_optional }}</span>
              <div class="form-part-add" @click="handleInfoCenterAdd">
                <i class="iconfont icon-a-icon-Add1x"></i>
                <span class="add">{{ LP.lang_add }}</span>
              </div>
            </h4>
            <el-form-item ref="ElFormItemInfoCenter" class="full-position grey-bg" prop="info_center">
              <div
                v-for="(item, index) in form.info_center"
                :key="index"
                class="vertical-spacing info-center-row"
              >
                <div class="left-wrap">
                  <el-select
                    v-model="item.menu_id"
                    :placeholder="LP.lang_file_type"
                    clearable
                    :class="{'is-not-error': !(!item.menu_id && !!item.download)}"
                    @change="handleSelectEmit('ElFormItemInfoCenter')"
                  >
                    <el-option
                      v-for="(item, index) in infoCenterOptions"
                      :key="index"
                      :label="item.menu_name"
                      :value="item.menu_id"
                    />
                  </el-select>
                </div>
                <div class="right-wrap">
                  <row-upload
                    v-model="form.info_center[index]"
                    :class="{'is-not-error': !(!!item.menu_id && !item.download)}"
                    @delete="handleInfoCenterDelete(index)"
                  />
                </div>
              </div>
            </el-form-item>
          </div>
          <div id="availability" class="form-part-wrap">
            <h4 class="form-part-title">{{ LP.lang_availability }}<span class="sub">{{ LP.lang_required }}</span></h4>
            <!-- <h4 class="form-part-title">{{ LP.lang_availability }}</h4> -->
            <el-form-item class="full-position 1012" prop="label">
              <div
                v-for="(item, index) in labelOptions"
                :key="index"
                class="availability-item-wrap"
              >
                <h5 class="item-title">{{ item.menu_name }}:</h5>
                <label-check
                  v-model="labelValue[index]"
                  :options="item.label_list"
                  :prop="{id:'id', label: 'name'}"
                />
              </div>
              <div class="availability-item-wrap">
                <h5 class="item-title">{{ LP.lang_available_language }}:</h5>
                <label-check v-model="availableLanguage" type="input" />
                <!-- <h6 class="default-language">Default language: English</h6> -->
              </div>
            </el-form-item>
          </div>
        </el-form>
        <div class="submit-wrap">
          <el-button class="submit-btn" type="primary" @click="handleSubmitConfirm">{{ LP.lang_submit }}</el-button>
        </div>
      </div>
    </div>
    <public-dialog
      :visible="submitDialog"
      @close="close"
      @cancel="close"
      @submit="handleSubmit"
    >
      <p class="dialog-tip">{{ LP.lang_notice_description }}</p>
      <div class="img-wrap">
        <img class="dialog-img" src="@/assets/img/myPage/noInfo.png" alt="" />
      </div>
    </public-dialog>
  </div>
</template>

<script>
import { Affix } from 'vue-affix'
import PageTop from '@/components/myPage/PageTop'
import CropperUpload from '@/components/myPage/CropperUpload'
import RowUpload from '@/components/myPage/RowUpload'
import LabelCheck from '@/components/myPage/LabelCheck'
import PublicDialog from '@/components/public/PublicDialog'
import { scrollToTop, calculateTop } from '@/utils/utils.js'
import { solutionTypeApi, productIconApi, infoCenterApi, labelOptionsApi, solutionSaveApi } from '@/api/myPage'
import { solutionDetailApi } from '@/api/solutions'
import cancelAxios from '@/mixin/cancelAxios.js'
import emitter from '@/mixin/emitter.js'

export default {
  components: {
    Affix,
    PageTop,
    CropperUpload,
    RowUpload,
    LabelCheck,
    PublicDialog
  },
  mixins: [cancelAxios, emitter],
  data() {
    const label = (rule, value, callback) => {
      this.labelValue.forEach
      if (this.labelValue.some(i => !i.length) || !this.availableLanguage.length) {
        return callback(new Error('required'))
      } else {
        callback()
      }
    }

    const partner_product_icon = (rule, value, callback) => {
      if (value.every(i => !i.product_name && !i.icon)) {
        return callback(new Error('required'))
      } else if (value.some(i => (!!i.product_name) !== (!!i.icon))) {
        return callback(new Error('required'))
      } else {
        callback()
      }
    }

    const how_works = (rule, value, callback) => {
      if (value.every(i => !i.title && !i.image)) {
        return callback(new Error('required'))
      } else if (value.some(i => !!i.title && !i.image)) {
        return callback(new Error('required'))
      } else {
        callback()
      }
    }

    const info_center = (rule, value, callback) => {
      if (value.some(i => (!!i.menu_id) !== (!!i.download))) {
        return callback(new Error('required'))
      } else {
        callback()
      }
    }

    const key_features = (rule, value, callback) => {
      if (value.every(i => !i.name)) {
        return callback(new Error('required'))
      } else {
        callback()
      }
    }

    return {
      errObj: {
        cover_image: '1001',
        short_intro: '1002',
        technical_email: '1003',
        sales_email: '1004',
        solution_type: '1005',
        solution_image: '1006',
        solution_description: '1007',
        dahua_product_icon: '1008',
        partner_product_icon: '1009',
        key_features: '1010',
        how_works: '1011',
        label: '1012'
      }, // 错误信息数字class对照表
      pageLoading: false,
      pageLoadingText: this.LP.lang_in_obtaining_data,
      solutionId: '',
      audit_status: '4', // 1发布 2审核中 3未发布 4保存 5已发布的保存
      solutionTypeOptions: [],
      dahuaIconOptions: [],
      partnerIconOptions: [],
      infoCenterOptions: [],
      labelOptions: [],
      anchor: [
        {
          name: this.LP.lang_basic_info, // 侧边显示名称
          anchor: 'basicInfo', // 侧边点击后定位的id
          validProps: {
            'cover_image': false,
            'short_intro': false,
            'solution_type': false
          } // 此大分类需要验证的字段
        },
        {
          name: this.LP.lang_solution_description,
          anchor: 'solutionDescription',
          validProps: {
            'solution_image': false,
            'solution_description': false
          }
        },
        {
          name: this.LP.lang_interoperability,
          anchor: 'interoperability',
          validProps: {
            'dahua_product_icon': false,
            'partner_product_icon': false
          }
        },
        {
          name: this.LP.lang_key_features,
          anchor: 'keyFeatures',
          validProps: {
            'key_features': false
          }
        },
        {
          name: this.LP.lang_how_it_works,
          anchor: 'howItWorks',
          validProps: {
            'how_works': false
          }
        },
        {
          name: this.LP.lang_info_center,
          anchor: 'infoCenter',
          validProps: {
            'info_center': false
          }
        },
        {
          name: this.LP.lang_availability,
          anchor: 'availability',
          validProps: {
            'label': false
          }
        }
      ],
      form: {
        solution_name: this.LP.lang_solution_name,
        cover_image: '',
        short_intro: '',
        technical_email: '',
        sales_email: '',
        solution_type: [],
        solution_image: '',
        solution_description: '',
        dahua_product_icon: [],
        dahua_product_description: '',
        partner_product_icon: [
          { product_name: '', icon: '' },
          { product_name: '', icon: '' },
          { product_name: '', icon: '' },
          { product_name: '', icon: '' },
          { product_name: '', icon: '' },
          { product_name: '', icon: '' }
        ],
        partner_product_description: '',
        key_features: [
          { name: '' },
          { name: '' },
          { name: '' }
        ],
        how_works: [
          { image: '', title: '' },
          { image: '', title: '' },
          { image: '', title: '' }
        ],
        info_center: [
          { menu_id: '', file_name: '', download: '', hits: '', only_code: '' },
          { menu_id: '', file_name: '', download: '', hits: '', only_code: '' }
        ],
        label: [],
        custom_label: []
      },
      labelValue: [],
      availableLanguage: [],
      rules: {
        cover_image: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        short_intro: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        technical_email: [
          { required: true, message: 'required', trigger: ['blur'] },
          { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
        ],
        sales_email: [
          { required: true, message: 'required', trigger: ['blur'] },
          { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
        ],
        solution_type: [
          { required: true, message: 'required', trigger: ['change'] }
        ],
        solution_description: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        dahua_product_icon: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        partner_product_icon: [
          { required: true, validator: partner_product_icon, message: 'required', trigger: ['blur'] }
        ],
        key_features: [
          { required: true, validator: key_features, message: 'required', trigger: ['blur'] }
        ],
        info_center: [
          { validator: info_center, message: 'required', trigger: ['blur'] }
        ],
        label: [
          { validator: label, message: 'required', trigger: ['blur'] }
        ]
      },
      submitDialog: false // 提交确认弹窗
    }
  },
  computed: {
    // 根据路由上的id判断当前的是否是新增的solution
    isNew() {
      return !this.$route.params.id
    }
  },
  async created() {
    this.solutionId = this.$route.params.id ? this.$route.params.id : ''
    if (this.isNew && this.$route.query.solutionName) {
      this.form.solution_name = this.$route.query.solutionName
    }
    await this.handleGetLabelOptions()
    if (this.solutionId) {
      this.handleGetSolutionDetail()
    }
    this.handleGetSolutionType()
    this.handleGetIcon(1)
    this.handleGetIcon(2)
    this.handleGetInfoCenter()
  },
  methods: {
    /** ************************** 初始化数据 ************************/
    // 获取solution页面的数据
    handleGetSolutionDetail() {
      this.pageLoading = true
      this.pageLoadingText = this.LP.lang_in_obtaining_data
      solutionDetailApi({
        solution_id: this.solutionId,
        menu: 1
      }).then(res => {
        if (res.status === '00') {
          const data = res.data
          if (data.audit_status === '2') { // 如果是审核中，跳转到in review页面
            this.$router.replace('/mypage/solution/inreview')
          }
          this.audit_status = data.audit_status
          this.form.partner_product_icon.forEach((item, index) => {
            if (index < data.partner_product_icon.length) {
              item.product_name = data.partner_product_icon[index].product_name
              item.icon = data.partner_product_icon[index].icon
            }
          })
          if (data.key_features.length) {
            this.form.key_features = data.key_features
          }
          if (data.how_works.length) {
            this.form.how_works = data.how_works
          }
          if (data.info_center.length) {
            this.form.info_center = data.info_center.map(i =>
              ({
                menu_id: i.menu_id,
                file_name: i.file_name,
                download: i.download,
                hits: i.hits,
                only_code: i.only_code
              })
            )
          }
          this.form = {
            ...this.form,
            solution_name: data.solution_name,
            cover_image: data.cover_image,
            short_intro: data.short_intro,
            technical_email: data.technical_email,
            sales_email: data.sales_email,
            solution_type: data.solution_type.map(i => i.id),
            solution_image: data.solution_image,
            solution_description: data.solution_description,
            dahua_product_icon: data.dahua_product_icon.map(i => i.id),
            dahua_product_description: data.dahua_product_description,
            partner_product_description: data.partner_product_description
          }

          data.label.forEach(mainItem => {
            this.labelOptions.forEach((item, index) => {
              if (item.label_list.some(i => i.id === mainItem.id)) {
                this.labelValue[index].push(mainItem)
              }
            })
          })
          this.availableLanguage = data.custom_label.map(i => ({ label: i }))
          // 先校验让侧边颜色正确，后清除校验是为了让页面看起来整洁，也可以不清楚
          this.$nextTick(() => {
            this.$refs.form.validate(_ => {})
            this.$refs.form.clearValidate()
            this.pageLoading = false
          })
        } else {
          this.$message.error('Data acquisition failure')
          this.pageLoading = false
        }
      }).catch(_ => {
        this.pageLoading = false
      })
    },
    // 获取solution type
    handleGetSolutionType() {
      solutionTypeApi().then(res => {
        this.solutionTypeOptions = res.data
      })
    },
    // 获取product icon 1大华 2partner
    handleGetIcon(type) {
      if (type === 1) {
        productIconApi(type).then(res => {
          this.dahuaIconOptions = res.data
        })
      } else {
        productIconApi(type).then(res => {
          this.partnerIconOptions = res.data
        })
      }
    },
    // 获取info center下拉数据
    handleGetInfoCenter() {
      infoCenterApi().then(res => {
        this.infoCenterOptions = res.data
      })
    },
    // 获取label数据
    async handleGetLabelOptions() {
      const res = await labelOptionsApi()
      this.labelOptions = res.data
      this.labelOptions.forEach(_ => {
        this.labelValue.push([])
      })
    },
    /** ************************** 表单项新增或删除 ************************/
    // key feature新增
    handleKeyAdd() {
      this.form.key_features.push({ name: '' })
    },
    // key feature删除
    handleKeyDelete(i) {
      if (this.form.key_features.length > 1) { this.form.key_features.splice(i, 1) }
      this.handleSelectEmit('ElFormItemKey')
    },
    // info center新增
    handleInfoCenterAdd() {
      this.form.info_center.push({
        menu_id: '', file_name: '', download: '', hits: '', only_code: ''
      })
    },
    // info center删除
    handleInfoCenterDelete(i) {
      this.form.info_center.splice(i, 1)
      this.handleSelectEmit('ElFormItemInfoCenter')
    },
    /** ************************** 表单提交 ************************/
    // solution name修改
    handleNameChange(val) {
      this.form.solution_name = val
    },
    // 关闭弹窗
    close() {
      this.cancelRequest()
      this.submitDialog = false
    },
    // 暂存
    handleSave() {
      this.$refs.form.clearValidate()
      this.form.label = this.labelValue.reduce((prev, cur) => {
        prev = [...prev, ...cur.map(i => i.id)]
        return prev
      }, [])
      this.form.custom_label = this.availableLanguage.length > 0 ? this.availableLanguage.map(i => i.label) : ['English']
      this.pageLoading = true
      this.pageLoadingText = this.LP.lang_in_save
      solutionSaveApi({
        ...this.form,
        type: this.isNew ? 1 : 2, // 1添加 2修改
        solution_id: this.solutionId,
        audit_status: '4'
      }).then(res => {
        if (res.status === '00') {
          this.$message.success('Save success')
          if (!this.solutionId) {
            this.$router.replace(`/mypage/solution/edit/${res.data}`)
            this.solutionId = res.data
          }
          this.handleGetSolutionDetail()
        } else {
          this.$message.success('Save failed')
        }
        this.pageLoading = false
      }).catch(_ => {
        this.pageLoading = true
      })
    },
    // 表单提交确认弹窗
    handleSubmitConfirm() {
      this.$refs.form.validate((valid, errs) => {
        if (valid) {
          this.submitDialog = true
        } else {
          const errNums = []
          Object.keys(errs).forEach(item => {
            errNums.push(this.errObj[item])
          })
          errNums.sort((a, b) => {
            return a - b
          })
          // 手写原生JS实现滚动
          // this.scrollToCmtList(errNums[0])
          // errNums.length = 0
          // this.scrollToCmtList(errNums[0])
          // 调用库函数实现滚动
          const isError = document.getElementsByClassName(errNums[0])
          isError[0].scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          })
          this.$message.error('Required items can not be empty')
        }
      })
    },
    // 实现滚动条平滑滚动的方法
    scrollToCmtList(tag) {
      if (!tag) { return }
      // 1.1 返回文档在垂直方向已滚动的像素值
      const now = window.scrollY
      // 1.2 目标位置（文章信息区域的高度）
      let dist = document.getElementsByClassName(tag).offsetHeight
      // 1.3 可滚动高度 = 整个文档的高度 - 浏览器窗口的视口（viewport）高度
      const avalibleHeight =
        document.documentElement.scrollHeight - window.innerHeight
      // 2.1 如果【目标高度】 大于 【可滚动的高度】
      if (dist > avalibleHeight) {
        // 2.2 就把目标高度，设置为可滚动的高度
        dist = avalibleHeight
      }
      // 3. 动态计算出步长值
      const step = (dist - now) / 10
      setTimeout(() => {
        // 4.2 如果当前的滚动的距离不大于 1px，则直接滚动到目标位置，并退出递归
        if (Math.abs(step) <= 1) {
          return window.scrollTo(0, dist)
        }
        // 4.1 每隔 10ms 执行一次滚动，并递归地进行下一次的滚动
        window.scrollTo(0, now + step)
        this.scrollToCmtList()
      }, 10)
    },
    // 表单提交
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // 将label模块id合并
          this.form.label = this.labelValue.reduce((prev, cur) => {
            prev = [...prev, ...cur.map(i => i.id)]
            return prev
          }, [])
          this.form.custom_label = this.availableLanguage.length > 0 ? this.availableLanguage.map(i => i.label) : ['English']
          this.loading = true
          solutionSaveApi({
            ...this.form,
            type: this.isNew ? 1 : 2, // 1添加 2修改
            solution_id: this.solutionId,
            audit_status: ''
          }, this.cancelToken()).then(res => {
            if (res.status === '00') {
              this.$message.success('submit successfully')
              this.$router.replace('/mypage/solution/inreview')
            } else {
              this.$message.success('submit failure')
            }
            this.loading = false
          }).catch(_ => {
            this.loading = false
          })
        }
      })
    },
    /** ************************** 其他 ************************/
    // 侧边点击页面定位
    scroll(anchor) {
      const node = document.querySelector(`#${anchor}`)
      const offsetTop = calculateTop(node)
      scrollToTop(offsetTop - 90)
    },
    // 监听校验规则 改变侧边栏颜色
    listenValidate(prop, status, message) {
      this.anchor.forEach(item => {
        for (const i in item.validProps) {
          if (i === prop) {
            item.validProps[i] = status
          }
        }
      })
    },
    // select组件change触发校验
    handleSelectEmit(ref) {
      this.$refs[ref].$emit.apply(this.$refs[ref], ['el.form.blur'].concat([]))
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.solution-edit-page {
  padding-top: 48px;
  & > {
    /deep/ .el-loading-mask {
      z-index: 90;
      .el-loading-spinner {
        position: fixed;
        left: 0;
        right: 0;
        width: unset;
      }
    }
  }
  .solution-inner {
    margin-top: 24px;
    .anchor-sider-wrap {
      width: 240px;
      min-height: 200px;
      float: left;
      margin-right: 30px;
      .vue-affix {
        width: 240px;
      }
      .anchor {
        border: 1px solid #E4E7ED;
        .anchor-item {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 16px;
          cursor: pointer;
          & + .anchor-item {
            border-top: 1px solid #E4E7ED;
          }
          &:hover {
            background: #f7f7f7;
          }
          .number {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #909399;
            line-height: 24px;
            text-align: center;
            color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            &.isValid {
              background: @theme;
            }
          }
          .name {
            font-size: 16px;
            color: #333333;
            padding-left: 16px;
          }
        }
      }
    }
    .form-wrap {
      overflow: hidden;
      .common-height {
        height: 120px;
        /deep/ .el-textarea__inner {
          min-height: 120px !important;
         .scroll-bar();
        }
      }
      .form-part-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 48px;
        .form-part-title {
          position: relative;
          width: 100%;
          font-size: 24px;
          font-weight: 600;
          color: #303133;
          line-height: 32px;
          padding-bottom: 16px;
          border-bottom: 1px solid #E4E7ED;
          margin-bottom: 23px;
          .sub {
            padding-left: 16px;
            font-size: 14px;
            color: #909399;
            line-height: 19px;
            font-weight: 500;
          }
          .form-part-add {
            font-weight: normal;
            position: absolute;
            right: 0;
            bottom: 8px;
            color: @theme;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .transition();
            &:hover {
              .iconfont {
                transform: rotate(180deg);
              }
            }
            .iconfont {
              color: @theme;
              font-size: 24px;
              .transition();
            }
            .add {
              font-size: 16px;
              padding-left: 6px;
            }
          }
        }
        .form-part-subtitle {
          margin-bottom: 16px;
          .item {
            font-size: 16px;
            color: #333333;
            line-height: 21px;
            & + .item {
              padding-left: 72px;
            }
          }
        }
        .availability-item-wrap {
          width: 100%;
          & + .availability-item-wrap {
            margin-top: 8px;
          }
          // .default-language{
          //   margin-top: -20px;
          // }
          .item-title {
            font-size: 18px;
            color: #303133;
            line-height: 24px;
            margin-bottom: 16px;
          }
        }
      }
      /deep/ .el-form-item {
        margin-bottom: 24px;
        &.half-position {
          width: 49%;
        }
        &.full-position {
          width: 100%;
        }
        &.grey-bg {
          .el-form-item__content {
            background: #F4F4F5;
            padding: 16px;
          }
        }
        &.is-required {
          .el-form-item__label::before {
            display: none;
          }
          .label-wrap {
            .label-name {
              &::after {
                display: none;
                content: '*';
                color: #EC400B;
              }
            }
          }
        }
        &:not(.is-error) {
          .el-form-item__content {
            .el-input__inner,
            .el-textarea__inner {
              border: 1px solid #E4E7ED;
              &:hover,
              &:focus {
                border: 1px solid @theme;
              }
            }
          }
        }
        &.interoperability {
          .el-form-item__content {
            height: 536px;
          }
        }
        .el-form-item__label {
          width: 100%;
          padding-bottom: 9px;
          .label-wrap {
            line-height: 22px;
            height: 22px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .label-name {
              font-size: 16px;
              font-weight: 600;
              color: #333333;
              line-height: 22px;
            }
            .text-len {
              font-size: 14px;
              color: #909399;
              line-height: 19px;
            }
          }
        }
        .el-form-item__content {
          // 通用垂直间距
          .vertical-spacing {
            & + .vertical-spacing {
              margin-top: 16px;
            }
            // how it work间距
            &.how-it-work-spacing {
              position: relative;
              & + .how-it-work-spacing  {
                margin-top: 48px;
                &::before {
                  position: absolute;
                  left: -16px;
                  top: -32px;
                  content: "";
                  display: block;
                  height: 16px;
                  width: calc(100% + 32px);
                  background: #ffffff;
                }
              }
            }
          }
          // 表单校验时不添加红框
          .is-not-error {
            .el-input__inner,
            .el-textarea__inner {
              border: 1px solid #E4E7ED;
              &:hover,
              &:focus {
                border: 1px solid @theme;
              }
            }
          }
          .el-select,
          .el-input {
            width: 100%;
            .el-input__inner {
              height: 48px;
              line-height: 48px;
            }
            .el-input__suffix {
              color: #979797;
            }
          }
          .el-textarea__inner,
          .el-input__inner {
            &::placeholder {
              color: #979797;
            }
          }
          .el-input-group__append {
            background: rgb(254, 250, 251);
            padding: 0 12px;
            .delete-btn {
              display: block;
              font-size: 24px;
              line-height: 24px;
              color: #c8161d;
              cursor: pointer;
              .transition();
              &:hover {
                transform: rotate(180deg);
              }
            }
          }
          .half-position-tiny {
            width: calc(50% - 8px);
            margin-right: 16px;
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
          .half-position-tiny-18 {
            width: calc(50% - 9px);
            margin-right: 18px;
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
          .info-center-row {
            display: flex;
            justify-content: center;
            align-items: center;
            .left-wrap {
              width: 160px;
              margin-right: 16px;
              flex-shrink: 0;
            }
            .right-wrap {
              flex-grow: 1;
              max-width: calc(100% - 176px);
            }
          }
        }
      }
      .submit-wrap {
        text-align: center;
        margin-top: -24px;
        margin-bottom: 74px;
        .submit-btn {
          padding: 9px 20px;
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 22px;
        }
      }
    }
  }
}
/deep/.public-dialog-container {
  .el-dialog__body {
    .dialog-tip {
      word-break: normal;
      margin-bottom: 23px;
    }
    .img-wrap {
      text-align: center;
      .dialog-img {
        width: 160px;
      }
    }
  }
}
</style>
