import { _axios } from '@/plugins/axios.js'

export const apiUrl = {
  upload: '/home/upload',
  solutionType: '/solution/solution_type', // 解决方案类型
  productIcon: '/solution/solution_icon', // 图标数据
  infoCenter: '/solution/solution_info_center', // infocenter下拉数据
  labelOptions: '/solution/label', // 获取label选项数据
  solutionSave: '/solution/solution', // solution添加或修改
  myContract: '/user/my_contract', // 获取合同下载地址
  myCompany: '/user/company_page', // 公司
  userInfoUpdate: '/user/user_info_update', // 修改用户信息
  companyUpdate: '/user/company_page_update', // 修改公司信息
  successStoryEdit: 'user/succcess_stories_update', // 提交成功案例（Success Stories用户修改）
  relatedSolutions: '/solution/related_solutions', // 获取个人创建的解决方案列表
  successStories: '/user/success_stories' // 获取个人中心成功案例列表

}

// 文件上传
export const upload = (params, progress = function() {}, cancelToken) =>
  _axios.post(apiUrl.upload, params, { isFormData: true, onUploadProgress: progress.bind(this), cancelToken })
// 解决方案类型
export const solutionTypeApi = () => _axios.get(apiUrl.solutionType)
// 图标数据
export const productIconApi = (params) => _axios.get(apiUrl.productIcon + `?menu_id=${params}`)
// info center下拉数据
export const infoCenterApi = () => _axios.get(apiUrl.infoCenter)
// solution添加或修改
export const solutionSaveApi = (params, cancelToken) => _axios.post(apiUrl.solutionSave, params, { cancelToken })
// 获取label选项数据
export const labelOptionsApi = () => _axios.get(apiUrl.labelOptions)
// 获取合同下载地址
export const getMyContract = () => _axios.get(apiUrl.myContract)
// 获取合同下载地址
export const getMyCompany = () => _axios.get(apiUrl.myCompany)
// 修改用户信息
export const userInfoUpdateApi = (params) => _axios.post(apiUrl.userInfoUpdate, params)
// 修改/暂存公司信息 audit 提交传2  暂存传4
export const companyUpdateApi = (params) => _axios.post(apiUrl.companyUpdate, params)
// 提交成功案例（Success Stories用户修改）
export const successStoryEditApi = (params) => _axios.post(apiUrl.successStoryEdit, params)
// 获取个人创建的解决方案列表
export const relatedSolutionsApi = (params) => _axios.post(apiUrl.relatedSolutions, params)
// 获取个人中心成功案例列表
export const successStoriesApi = (params) => _axios.get(apiUrl.successStories + `?company_id=${params.company_id}&id=${params.id}`)
