import { _axios } from '@/plugins/axios.js'

const apiUrl = {
  programHome: '/partner/partner_home', // partners首页
  partnerDetail: '/partner/partner_detail', // partners注册页
  partnersList: '/partner/partner_menu', // Partners 类别下的数据
  partnersCategory: '/partner/partner_menu_select', // Partners 类别数据
  partnersSubPage: '/partner_program/application_marketplace_detail', // application_marketplace 中数据详情
  partnerHits: '/partner_program/application_hits', // app点击量
  partnerContactForm: '/partner_program/application_register' // contact 按钮表单
}

// 注册页
export const programHomeApi = (params) => _axios.post(apiUrl.programHome, params)
// 详情页
export const partnerDetailApi = (params) => _axios.post(apiUrl.partnerDetail, params)
// Partners 类别下的数据
export const partnersListApi = (params) => _axios.post(apiUrl.partnersList, params)
// Partners 类别数据
export const partnersCategoryApi = (params) => _axios.post(apiUrl.partnersCategory, params)
// application_marketplace 中数据详情
export const subPageDetailApi = (params) => _axios.post(apiUrl.partnersSubPage, params)
//  app 点击量
export const partnerHitsApi = (params) => _axios.post(apiUrl.partnerHits, params)
// contact 按钮表单
export const partnerContactFormApi = (params) => _axios.post(apiUrl.partnerContactForm, params)

