<template>
  <div
    v-loading="pageLoading"
    :element-loading-text="pageLoadingText"
    class="container company-edit-page"
  >
    <page-top
      :title="LP.lang_company_page"
      :status="audit_status"
      @save="handleSave"
    />
    <p class="page-desc">{{ LP.lang_company_page_description }}</p>
    <div class="form-wrap">
      <el-form
        ref="companyForm"
        :model="companyForm"
        :rules="companyFormRules"
        label-position="top"
      >
        <div class="form-part-wrap">
          <el-form-item class="layout-3 is-required" prop="company_name">
            <template #label>
              <p class="label-wrap">
                <span class="label-name">{{ LP.lang_dipp_company_name }}</span>
              </p>
            </template>
            <el-input
              v-model="companyForm.company_name"
              clearable
            />
          </el-form-item>
          <el-form-item class="layout-3 is-required" prop="company_url">
            <template #label>
              <p class="label-wrap">
                <span class="label-name">{{ LP.lang_company_url }}</span>
              </p>
            </template>
            <el-input
              v-model="companyForm.company_url"
              clearable
            />
          </el-form-item>
          <el-form-item class="layout-3 is-required" prop="company_email">
            <template #label>
              <p class="label-wrap">
                <span class="label-name">{{ LP.lang_company_email }}</span>
              </p>
            </template>
            <el-input
              v-model="companyForm.company_email"
              clearable
            />
          </el-form-item>
        </div>
        <div class="form-part-wrap">
          <el-form-item class="layout-2 is-required" prop="company_logo">
            <template #label>
              <p class="label-wrap">
                <span class="label-name">{{ LP.lang_company_logo }}</span>
              </p>
            </template>
            <cropper-upload
              v-model="companyForm.company_logo"
              class-name="common-height"
              :hit-size="[308, 206]"
            />

          </el-form-item>
          <el-form-item class="layout-2 is-required" prop="company_description">
            <template #label>
              <p class="label-wrap">
                <span class="label-name">{{ LP.lang_company_description }}</span>
                <span class="text-len">{{ companyForm.company_description.length }}/1000</span>
              </p>
            </template>
            <el-input
              v-model="companyForm.company_description"
              resize="none"
              class="common-height"
              type="textarea"
              :maxlength="1000"
            />
          </el-form-item>
        </div>
        <div class="form-part-wrap">
          <el-form-item ref="ElFormItemRelated" class="layout-3 is-required" prop="related_solutions">
            <template #label>
              <p class="label-wrap">
                <span class="label-name none">{{ LP.lang_related_solutions }}</span>
              </p>
            </template>
            <el-select
              v-model="companyForm.related_solutions"
              :placeholder="LP.lang_select"
              multiple
              clearable
              @change="handleSelectEmit('ElFormItemRelated')"
            >
              <el-option
                v-for="(item, index) in relatedSolutionOptions"
                :key="index"
                :label="item.solution_name"
                :value="item.solution_id"
              />
            </el-select>
          </el-form-item>
          <el-form-item ref="ElFormItemEco" class="layout-3 is-required" prop="eco_partner_type">
            <template #label>
              <p class="label-wrap">
                <span class="label-name">{{ LP.lang_eco_partner_type }}</span>
              </p>
            </template>
            <el-select
              v-model="companyForm.eco_partner_type"
              :placeholder="LP.lang_select"
              clearable
              multiple
              @change="handleSelectEmit('ElFormItemEco')"
            >
              <el-option
                v-for="(item, index) in ecoOptions"
                :key="index"
                :label="item.menu_name"
                :value="item.menu_id"
              />
            </el-select>
          </el-form-item>
          <el-form-item ref="ElFormItemDhop" class="layout-3 is-required" prop="dhop_partner_type">
            <template #label>
              <p class="label-wrap">
                <span class="label-name">{{ LP.lang_dhop_partner }}</span>
              </p>
            </template>
            <el-select
              v-model="companyForm.dhop_partner_type"
              :placeholder="LP.lang_select"
              clearable
              multiple
              @change="handleSelectEmit('ElFormItemDhop')"
            >
              <el-option
                v-for="(item, index) in dhopOptions"
                :key="index"
                :label="item.menu_name"
                :value="item.menu_id"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <my-list
        style="margin-top: 18px;"
        v-bind="successStories"
        @btnClick="handleAddStory"
        @edit="handleEditStory"
      />
      <div class="submit-wrap">
        <el-button class="submit-btn" type="primary" @click="handlePublish">{{ LP.lang_publish }}</el-button>
      </div>
    </div>
    <story-dialog
      :visible="storyDialog"
      :edit-id="editId"
      :mypage-id="mypageId"
      @submit="handleStorySubmit"
      @cancel="handleStoryCancel"
    />
    <public-dialog
      :visible="submitDialog"
      @close="close"
      @cancel="close"
      @submit="handleSubmit"
    >
      <p class="dialog-tip">{{ LP.lang_notice_description }}</p>
      <div class="img-wrap">
        <img class="dialog-img" src="@/assets/img/myPage/noInfo.png" alt="" />
      </div>
    </public-dialog>
  </div>
</template>

<script>
import PageTop from '@/components/myPage/PageTop'
import CropperUpload from '@/components/myPage/CropperUpload'
import MyList from '@/components/myPage/MyList'
import StoryDialog from '@/components/myPage/StoryDialog'
import PublicDialog from '@/components/public/PublicDialog'
import {
  getMyCompany,
  companyUpdateApi,
  relatedSolutionsApi,
  successStoriesApi
} from '@/api/myPage'
import { partnersCategoryApi } from '@/api/partners'
export default {
  components: {
    PageTop,
    CropperUpload,
    MyList,
    StoryDialog,
    PublicDialog
  },
  data() {
    return {
      pageLoading: false,
      pageLoadingText: this.LP.lang_in_obtaining_data,
      audit_status: '4', // 当前状态
      companyFormRules: {
        company_name: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        company_url: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        company_email: [
          { required: true, message: 'required', trigger: ['blur'] },
          { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
        ],
        company_logo: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        company_description: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        eco_partner_type: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        dhop_partner_type: [
          { required: true, message: 'required', trigger: ['blur'] }
        ]
      },
      // 公司信息表单
      companyForm: {
        company_name: '',
        company_url: '',
        company_email: '',
        company_logo: '',
        company_description: '',
        related_solutions: [],
        eco_partner_type: [],
        dhop_partner_type: [],
        successStories: [] // 此公司绑定的成功案例的id数组
      },
      // related solutions下拉数据
      relatedSolutionOptions: [],
      // eco下拉数据
      ecoOptions: [],
      // dhop下拉数据
      dhopOptions: [],
      // 成功案例弹窗
      storyDialog: false,
      editId: '',
      mypageId: '', // mypage首页id
      // Success Stories部分
      successStories: {
        title: this.LP.lang_success_stories,
        showTitleBtn: true,
        data: [],
        rowBtns: [{
          name: this.LP.lang_dhop_edit,
          btn: 'edit'
        }]
      },
      submitDialog: false // 提交弹窗
    }
  },
  created() {
    this.handleGetCompany() // 获取当前company数据
    this.handleGetRelated() // 获取related solutions下拉数据
    this.handleGetEco() // 获取eco下拉数据
    this.handleGetDhop() // 获取DHOP下拉数据
  },
  methods: {
    /** ********************** 初始化 ************************/
    // 获取当前company数据
    async handleGetCompany() {
      this.pageLoading = true
      this.pageLoadingText = this.LP.lang_in_obtaining_data
      // TODO:这里调用数据获取接口
      await getMyCompany().then(res => {
        this.companyForm = {
          company_name: res.data.company_name,
          company_url: res.data.company_url,
          company_email: res.data.company_email,
          company_logo: res.data.company_logo,
          company_description: res.data.company_description,
          related_solutions: res.data.related_solutions,
          eco_partner_type: res.data.eco_menu,
          dhop_partner_type: res.data.dhop_menu,
          successStories: []
        }
        this.mypageId = res.data.id
      })
      this.handleGetStories() // 获取成功案例列表数据
      this.pageLoading = false
    },
    // 获取related solutions下拉数据
    handleGetRelated() {
      // TODO:这里调用接口，给this.relatedSolutionOptions赋值
      relatedSolutionsApi({
        type: 3
      }).then(res => {
        this.relatedSolutionOptions = res.data.solutionInfo
      })
    },
    // 获取eco下拉数据
    handleGetEco() {
      // TODO:这里调用接口，给this.ecoOptions赋值
      partnersCategoryApi({
        type: 1
      }).then(res => {
        this.ecoOptions = res.data
      })
    },
    // 获取DHOP下拉数据
    handleGetDhop() {
      // TODO:这里调用接口，给this.dhopOptions赋值
      partnersCategoryApi({
        type: 2
      }).then(res => {
        this.dhopOptions = res.data
      })
    },
    // 获取成功案例
    handleGetStories() {
      // TODO:这里调用接口，给this.successStories.data赋值，字段名为id和name
      successStoriesApi({
        company_id: this.mypageId,
        id: ''
      }).then(res => {
        if (res.data.length > 0) {
          res.data.forEach(item => {
            this.successStories.data.push({
              name: item.title,
              id: item.id
            })
          })
        }
      })
    },

    /** ********************** 表单操作 ************************/
    // company暂存
    handleSave() {
      this.$refs.companyForm.clearValidate() // 清除校验
      this.pageLoading = true
      this.pageLoadingText = this.LP.lang_in_save
      // 暂存不需要将必填项全部填写完，随时可以保存
      companyUpdateApi({
        company_name: this.companyForm.company_name,
        company_url: this.companyForm.company_url,
        company_email: this.companyForm.company_email,
        company_logo: this.companyForm.company_logo,
        company_description: this.companyForm.company_description,
        related_solutions: this.companyForm.related_solutions,
        eco_menu: this.companyForm.eco_partner_type,
        dhop_menu: this.companyForm.dhop_partner_type,
        audit: 4
      }).then(res => {
        if (res.status === '00') {
          this.$message.success(res.message)
          this.pageLoading = false
        }
      }).catch(err => {
        this.$message.error(err.data.message)
        this.pageLoading = false
      })
    },
    // 打开提交弹窗
    handlePublish() {
      this.submitDialog = true
    },
    //  关闭提交弹窗
    close() {
      this.submitDialog = false
    },
    // 提交弹窗并且关闭提交弹窗
    handleSubmit() {
      this.$refs.companyForm.validate(valid => {
        if (valid) {
          this.pageLoading = true
          this.pageLoadingText = this.LP.lang_in_submission
          // 这里调用提交接口
          companyUpdateApi({
            company_name: this.companyForm.company_name,
            company_url: this.companyForm.company_url,
            company_email: this.companyForm.company_email,
            company_logo: this.companyForm.company_logo,
            company_description: this.companyForm.company_description,
            related_solutions: this.companyForm.related_solutions,
            eco_menu: this.companyForm.eco_partner_type,
            dhop_menu: this.companyForm.dhop_partner_type,
            audit: 2
          }).then(res => {
            if (res.status === '00') {
              this.$message.success(res.message)
              this.submitDialog = false
              this.$router.push(`/mypage/solution/inreview`)
            }
          }).catch(err => {
            this.$message.success(err.message)
          })
          this.pageLoading = false
        }
      })
    },
    /** ********************** 成功案例操作 ************************/
    // 添加成功案例
    handleAddStory() {
      this.editId = ''
      this.storyDialog = true
    },
    // 编辑成功案例
    handleEditStory({ item }) {
      this.editId = item.id
      this.storyDialog = true
    },
    // 提交成功案例
    handleStorySubmit() {
      this.storyDialog = false
    },
    // 关闭成功案例弹窗
    handleStoryCancel() {
      this.storyDialog = false
      this.editId = ''
    },
    // select组件change触发校验
    handleSelectEmit(ref) {
      this.$refs[ref].$emit.apply(this.$refs[ref], ['el.form.blur'].concat([]))
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.company-edit-page {
  padding-top: 48px;
  & > {
    /deep/ .el-loading-mask {
      z-index: 90;
      .el-loading-spinner {
        position: fixed;
        left: 0;
        right: 0;
        width: unset;
      }
    }
  }
  .page-desc {
    padding: 15px 0 42px;
    font-size: 16px;
    color: #606266;
    line-height: 21px;
  }
  .form-wrap {
    .layout-3 {
      width: calc(33.3% - 11px);
    }
    .layout-2 {
      width: calc(50% - 8px);
    }
    .common-height {
      height: 120px;
      /deep/ .el-textarea__inner {
        min-height: 120px !important;
        .scroll-bar();
      }
    }
    .form-part-wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      /deep/ .el-form-item {
        margin-bottom: 24px;
        &.is-required {
          .el-form-item__label::before {
            display: none;
          }
          .label-wrap {
            .label-name {
              &::after {
                content: '*';
                color: #EC400B;
              }
            }
            .none {
              &::after {
                content: '';
              }
            }
          }
        }
        &:not(.is-error) {
          .el-form-item__content {
            .el-input__inner,
            .el-textarea__inner {
              border: 1px solid #E4E7ED;
              &:hover,
              &:focus {
                border: 1px solid @theme;
              }
            }
          }
        }
        .el-form-item__label {
          width: 100%;
          padding-bottom: 9px;
          .label-wrap {
            line-height: 22px;
            height: 22px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .label-name {
              font-size: 16px;
              font-weight: 600;
              color: #333333;
              line-height: 22px;
            }
            .text-len {
              font-size: 14px;
              color: #909399;
              line-height: 19px;
            }
          }
        }
        .el-form-item__content {
          .el-select,
          .el-input {
            width: 100%;
            .el-input__inner {
              height: 48px;
              line-height: 48px;
            }
            .el-input__suffix {
              color: #979797;
            }
          }
          .el-textarea__inner,
          .el-input__inner {
            &::placeholder {
              color: #979797;
            }
          }
          // ?
          .el-input-group__append {
            background: rgb(254, 250, 251);
            padding: 0 12px;
            .delete-btn {
              display: block;
              font-size: 24px;
              line-height: 24px;
              color: #c8161d;
              cursor: pointer;
              .transition();
              &:hover {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    .submit-wrap {
      text-align: center;
      margin-top: 24px;
      margin-bottom: 98px;
      .submit-btn {
        padding: 9px 20px;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 22px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .form-wrap {
      .layout-3 {
        width: 100%;
      }
      .layout-2 {
        width: 100%;
      }
    }
  }
}
/deep/.public-dialog-container {
  .el-dialog__body {
    .dialog-tip {
      word-break: normal;
      margin-bottom: 23px;
    }
    .img-wrap {
      text-align: center;
      .dialog-img {
        width: 160px;
      }
    }
  }
}
</style>
