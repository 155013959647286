<template>
  <div class="mypage-home">
    <div class="welcome-banner">
      <div class="container">
        <img class="avator-default" src="@/assets/img/myPage/myPageBanner.png" alt="my page" />
        <div class="text-wrap">
          <h2 class="main">{{ LP.lang_welcome }}, {{ userName }}</h2>
          <p class="info">
            <span class="item">{{ LP.lang_dipp_company_name }}: {{ userCompany }}</span>
            <span class="item">{{ LP.lang_partner_type }}: {{ show ? userPartner : 'Reviewing' }}</span>
            <span v-if="userInfo.is_show === '0' || userInfo.is_show === '3'" class="item">
              {{ LP.lang_status_text }}
              {{ userInfo.is_show === '0' ? LP.	lang_under_review : '' }}
              {{ userInfo.is_show === '3' ? LP.lang_not_passed : '' }}
              ({{ userInfo.message }})
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="page-wrap">
      <div class="container">
        <my-list v-bind="profile" @edit="handleEditProfile" />
        <my-list v-bind="contract" @download="handleDownloadContract" />
        <my-list v-if="show" :show="show" v-bind="company" @edit="handleEditCompany" />
        <my-list v-if="show" v-bind="solution" @btnClick="handleAddSolution" @edit="handleEditSolution" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MyList from '@/components/myPage/MyList'
import { getMyContract, getMyCompany } from '@/api/myPage'
import { solutionListApi } from '@/api/solutions'
import { getCookie } from '@/utils/cookie'
export default {
  components: {
    MyList
  },
  data() {
    return {
      // Profile部分
      profile: {
        title: this.LP.lang_profile,
        data: [{
          name: this.LP.lang_my_profile
        }],
        rowBtns: [{
          name: this.LP.lang_check,
          btn: 'edit'
        }]
      },
      // My Contract部分
      contract: {
        title: this.LP.lang_my_contract,
        data: [],
        rowBtns: [{
          name: this.LP.lang_Download,
          btn: 'download'
        }]
      },
      // Company Page部分
      company: {
        title: this.LP.lang_company_page,
        data: [],
        rowBtns: [{
          name: this.LP.lang_dhop_edit,
          btn: 'edit'
        }]
      },
      // Solution Pages部分
      solution: {
        title: this.LP.lang_solution_pages,
        showTitleBtn: true,
        data: [],
        rowBtns: [{
          name: this.LP.lang_dhop_edit,
          btn: 'edit'
        }]
      },
      show: false
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.myPage.userInfo // 用户信息
    }),
    userName() {
      if (this.userInfo.firstName && this.userInfo.lastName) {
        return this.userInfo.firstName + ' ' + this.userInfo.lastName
      }
      return ''
    },
    userCompany() {
      return this.userInfo.companyName || ''
    },
    userPartner() {
      return this.userInfo.partnerLevel || ''
    }
  },
  created() {
    if (getCookie('isShow') === '1') {
      this.show = true
    } else {
      this.show = false
    }
    this.$store.dispatch('myPage/getUserInfo') // 获取用户信息
    this.handleGetMyContract()
    this.handleGetMyCompany()
    this.handleGetSolutions()
  },
  methods: {
    // 获取合同下载地址
    handleGetMyContract() {
      getMyContract().then(res => {
        if (!res.data || !res.data.length) {
          this.contract.data = []
        } else {
          this.contract.data = res.data.map(item => ({
            name: item.title,
            download: item.file
          }))
        }
      })
    },
    // 获取公司
    handleGetMyCompany() {
      getMyCompany().then(res => {
        this.company.data.push({
          name: res.data.company_name,
          type: res.data.audit
        })
      })
    },
    // 获取solutions
    handleGetSolutions() {
      solutionListApi({ type: 1 }).then(res => {
        res.data.solutionInfo.forEach(item => {
          this.solution.data.push({
            name: item.solution_name,
            type: item.audit_status,
            id: item.solution_id
          })
        })
      })
    },
    // 编辑个人信息
    handleEditProfile() {
      this.$router.push(`/mypage/profile`)
    },
    // 下载contact
    handleDownloadContract({ item }) {
      window.open(item.download)
    },
    // 编辑company
    handleEditCompany({ item }) {
      if (item.type === '2') {
        this.$router.push(`/mypage/solution/inreview`)
      } else {
        this.$router.push(`/mypage/company`)
      }
    },
    // 添加solution
    handleAddSolution() {
      this.$router.push(`/mypage/solution`)
    },
    // 编辑solution
    handleEditSolution({ item }) {
      if (item.type === '2') {
        // 审核中的不允许跳转到编辑页，直接去审核提示页面
        this.$router.push(`/mypage/solution/inreview`)
      } else {
        this.$router.push(`/mypage/solution/edit/${item.id}`)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.mypage-home {
  .welcome-banner {
    overflow: hidden;
    height: 180px;
    background: #232931;
    color: #FFF9DE;
    .container {
      position: relative;
      overflow: hidden;
      height: 100%;
    }
    .avator-default {
      position: absolute;
      bottom: 0;
      left: 18%;
      height: 140px;
      width: 140px;
      z-index: 1;
    }
    .text-wrap {
      position: relative;
      z-index: 2;
      margin-left: 35%;
      margin-top: 32px;
      .main {
        font-size: 36px;
        color: #FFF9DE;
        line-height: 48px;
        margin-bottom: 25px;
        font-weight: 500;
      }
      .info {
        font-size: 16px;
        color: #FFF9DE;
        line-height: 24px;
        .item {
          position: relative;
          display: inline-block;
          margin-right: 30px;
          &::after {
            content: '';
            display: block;
            width: 1px;
            height: 22px;
            background: #FFF9DE;
            position: absolute;
            right: -15px;
            bottom: 0;
          }
          &:last-child {
            margin-right: 0;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  .page-wrap {
    margin: 72px 0 84px;
  }
  @media screen and (max-width: 768px) {
    .welcome-banner {
      .text-wrap {
        margin-left: 0;
        margin-top: 24px;
        .main {
          font-size: 24px;
          line-height: 26px;
        }
        .info {
          font-size: 14px;
          .item {
            margin-right: 20px;
            &::after {
              height: 15px;
              right: -10px;
              bottom: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
