<template>
  <public-dialog
    :visible="visible"
    :show-cancel="false"
    :element-config="config"
    :loading="loading"
    @submit="submit"
    @cancel="cancel"
    @opened="opened"
  >
    <div class="story-edit-page">
      <div class="form-wrap">
        <el-form
          ref="storyForm"
          :model="storyForm"
          :rules="storyFormRules"
          label-position="top"
        >
          <div class="form-part-wrap">
            <el-form-item class="layout-2 is-required" prop="success_story_name">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_success_story_name }}</span>
                </p>
              </template>
              <el-input
                v-model="storyForm.success_story_name"
                clearable
              />
            </el-form-item>
            <el-form-item class="layout-2 is-required" prop="brochure">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_brochure }}</span>
                </p>
              </template>
              <row-upload
                v-model="storyForm.brochure"
                type="right"
              />
            </el-form-item>
          </div>
          <div class="form-part-wrap">
            <el-form-item class="layout-2 is-required" prop="company_logo">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_company_logo }}</span>
                </p>
              </template>
              <cropper-upload
                v-model="storyForm.company_logo"
                class-name="common-height"
                :hit-size="[308, 206]"
              />

            </el-form-item>
            <el-form-item class="layout-2 is-required" prop="description">
              <template #label>
                <p class="label-wrap">
                  <span class="label-name">{{ LP.lang_description	}}</span>
                  <span class="text-len">{{ storyForm.description.length }}/500</span>
                </p>
              </template>
              <el-input
                v-model="storyForm.description"
                resize="none"
                class="common-height"
                type="textarea"
                :maxlength="500"
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </public-dialog>
</template>

<script>
import PublicDialog from '@/components/public/PublicDialog'
import CropperUpload from '@/components/myPage/CropperUpload'
import RowUpload from '@/components/myPage/RowUpload'
import { successStoryEditApi, successStoriesApi } from '@/api/myPage'
export default {
  components: {
    PublicDialog,
    CropperUpload,
    RowUpload
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    editId: {
      type: [String, Number],
      default: ''
    },
    mypageId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    const brochure = (rule, value, callback) => {
      if (!value.file_name) {
        return callback(new Error('required'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      storyForm: {
        success_story_name: '',
        brochure: {
          file_name: '',
          download: ''
        },
        company_logo: '',
        description: ''
      },
      storyFormRules: {
        success_story_name: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        brochure: [
          { required: true, validator: brochure, message: 'required', trigger: ['blur'] }
        ],
        company_logo: [
          { required: true, message: 'required', trigger: ['blur'] }
        ],
        description: [
          { required: true, message: 'required', trigger: ['blur'] }
        ]
      }
    }
  },
  computed: {
    config() {
      return {
        // title根据id判断当前是编辑还是新增
        title: this.editId ? 'Edit Success Story' : 'Add Success Story',
        width: '1080px'
      }
    }
  },
  methods: {
    // 弹窗打开如果有editId表示编辑，没有表示新增
    opened() {
      if (this.editId) {
        // TODO:调用获取当前id的成功案例数据接口
        // 并且把获取到的值放到this.storyForm中
        successStoriesApi({
          company_id: this.mypageId,
          id: this.editId
        }).then(res => {
          res.data.forEach(item => {
            this.storyForm = {
              success_story_name: item.title,
              brochure: {
                file_name: item.file,
                download: item.file
              },
              company_logo: item.image,
              description: item.description
            }
          })
        })
      }
    },
    // 编辑提交 与 新增提交接口封装
    getSuccessStoryEdit(editId, type) {
      successStoryEditApi({
        file: this.storyForm.brochure.download,
        image: this.storyForm.company_logo,
        title: this.storyForm.success_story_name,
        description: this.storyForm.description,
        company_id: this.mypageId,
        id: editId,
        type: type // type 添加传1 修改传2
      }).then(res => {
        if (res.status === '00') {
          this.$message.success(res.message)
          this.cancel()
        }
      }).catch(err => {
        this.$message.error(err.data.message)
      })
      this.loading = false
    },
    // 编辑提交 与 新增提交
    submit() {
      this.$refs.storyForm.validate(valid => {
        if (valid) {
          // this.loading = true
          if (this.editId) {
            // TODO:编辑接口保存数据
            this.getSuccessStoryEdit(this.editId, 2)
          } else {
            // TODO:新增接口保存数据
            this.getSuccessStoryEdit('', 1)
          }
        }
      })
    },
    // 弹窗关闭
    cancel() {
      this.storyForm = {
        success_story_name: '',
        brochure: {
          file_name: '',
          download: ''
        },
        company_logo: '',
        description: ''
      }
      this.$nextTick(() => {
        this.loading = false
        this.$refs.storyForm.clearValidate()
        this.$emit('cancel')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.story-edit-page {
  .form-wrap {
    .layout-2 {
      width: calc(50% - 12px);
    }
    .common-height {
      height: 120px;
      /deep/ .el-textarea__inner {
        min-height: 120px !important;
        .scroll-bar();
      }
    }
    .form-part-wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      /deep/ .el-form-item {
        margin-bottom: 24px;
        &.is-required {
          .el-form-item__label::before {
            display: none;
          }
          .label-wrap {
            .label-name {
              &::after {
                content: '*';
                color: #EC400B;
              }
            }
          }
        }
        &:not(.is-error) {
          .el-form-item__content {
            .el-input__inner,
            .el-textarea__inner {
              border: 1px solid #E4E7ED;
              &:hover,
              &:focus {
                border: 1px solid @theme;
              }
            }
          }
        }
        .el-form-item__label {
          width: 100%;
          padding-bottom: 9px;
          .label-wrap {
            line-height: 22px;
            height: 22px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .label-name {
              font-size: 16px;
              font-weight: 600;
              color: #333333;
              line-height: 22px;
            }
            .text-len {
              font-size: 14px;
              color: #909399;
              line-height: 19px;
            }
          }
        }
        .el-form-item__content {
          .el-select,
          .el-input {
            width: 100%;
            .el-input__inner {
              height: 48px;
              line-height: 48px;
            }
            .el-input__suffix {
              color: #979797;
            }
          }
          .el-textarea__inner,
          .el-input__inner {
            &::placeholder {
              color: #979797;
            }
          }
        }
      }
    }

  }
  @media screen and (max-width: 768px) {
    .form-wrap {
      .layout-3 {
        width: 100%;
      }
      .layout-2 {
        width: 100%;
      }
    }
  }
}
</style>
