<template>
  <div class="solution-add-name-page">
    <div class="banner-wrap">
      <div class="container">
        <h2 class="title">{{ LP.lang_add_solution	}}</h2>
        <ul class="steps-list">
          <li v-for="(item, index) in steps" :key="index" class="steps-item">
            <div class="serial-number">
              <span class="number">{{ index + 1 }}</span>
            </div>
            <span class="name">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="container content-wrap">
      <p class="text">{{ LP.lang_add_solution_content	}}</p>
      <div class="form-wrap">
        <label class="form-label">{{ LP.lang_solution_name	}}</label>
        <el-input ref="input" v-model="solutionName" clearable @keyup.enter.native="next" />
        <el-button class="next" type="primary" @click="next">{{ LP.lang_add_solution_next }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [{
        name: this.LP.lang_add_content
      }, {
        name: this.LP.lang_add_solution_review
      }, {
        name: this.LP.lang_publish
      }],
      solutionName: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.input) {
        this.$refs.input.focus()
      }
    })
  },
  methods: {
    next() {
      if (this.solutionName) {
        this.$router.push('/mypage/solution/edit?solutionName=' + this.solutionName)
      } else {
        this.$message.error('Please enter the solution name')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.solution-add-name-page {
  .banner-wrap {
    background: #f4f4f5;
    padding-top: 72px;
    padding-bottom: 72px;
    .title {
      font-size: 36px;
      color: #303133;
      line-height: 48px;
      font-weight: normal;
      text-align: center;
    }
    .steps-list {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      .steps-item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .serial-number {
          width: 48px;
          height: 48px;
          background: @theme;
          border-radius: 50%;
          text-align: center;
          flex-shrink: 0;
          .number {
            font-size: 18px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 48px;
          }
        }
        .name {
          font-size: 24px;
          font-weight: bold;
          color: #303133;
          line-height: 32px;
          margin-left: 16px;
        }
        & + .steps-item {
          margin-left: 108px;
          .name {
            &::before {
              display: block;
              width: 60px;
              height: 1px;
              content: '';
              background: #979797;
              position: absolute;
              left: -80px;
              top: 25px;
            }
          }
        }
      }
    }
  }
  .content-wrap {
    padding-top: 32px;
    text-align: center;
    .text {
      font-size: 16px;
      color: #606266;
      line-height: 21px;
      padding-bottom: 16px;
      border-bottom: 1px solid #E4E7ED;
      margin-bottom: 40px;
      text-align: left;
    }
    .form-wrap {
      max-width: 416px;
      margin: auto;
      .form-label {
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        margin-bottom: 10px;
        text-align: center;
      }
      /deep/ .el-input__inner {
        height: 48px;
        line-height: 48px;
      }
      .next {
        margin-top: 32px;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 22px;
        padding: 9px 20px;
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .banner-wrap {
      .steps-list {
        .steps-item {
          .name {
            font-size: 20px;
            line-height: 28px;
            margin-left: 8px;
          }
          & + .steps-item {
            margin-left: 90px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .banner-wrap {
      .title {
        font-size: 28px;
        line-height: 48px;
      }
      .steps-list {
        .steps-item {
          .serial-number {
            width: 40px;
            height: 40px;
            .number {
              font-size: 16px;
              line-height: 40px;
            }
          }
          .name {
            font-size: 14px;
            line-height: 22px;
          }
          & + .steps-item {
            margin-left: 68px;
            .name {
              &::before {
                width: 40px;
                left: -54px;
                top: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
