<script>
import { STATUS } from '@/constant.js'
export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    // 是否可编辑
    editable: {
      type: Boolean,
      default: false
    },
    // 状态
    status: {
      type: String,
      default: '4' // 1发布 2审核中 3未发布 4保存 5已发布的保存
    }
  },
  data() {
    return {
      STATUS: STATUS,
      editing: false // 是否正在编辑状态
    }
  },
  mounted() {
    window.addEventListener('click', this.handleDocumentCloseEdit)
    this.$on('hook:beforeDestroy', () => {
      window.removeEventListener('click', this.handleDocumentCloseEdit)
    })
  },
  methods: {
    // 右侧保存
    handleSave() {
      this.$emit('save')
    },
    // 点击去编辑
    handleToEdit(e) {
      e.stopPropagation()
      this.editing = true
      this.$nextTick(() => {
        if (this.$refs.editInput) {
          this.$refs.editInput.focus()
        }
      })
    },
    // 编辑回车提交
    handleSubmit(e) {
      if (e.keyCode === 13) {
        if (!e.target.value) {
          this.$message.error(this.LP.lang_not_be_empty)
          return false
        }
        this.$emit('change', e.target.value)
        this.editing = false
      }
    },
    // 点击其他区域关闭编辑框
    handleDocumentCloseEdit(e) {
      if (this.editing && !this.$refs.container.contains(e.target)) {
        if (!this.$refs.editInput.value) {
          this.$message.error(this.LP.lang_not_be_empty)
        } else {
          this.$emit('change', this.$refs.editInput.value)
        }
        this.editing = false
      }
    }
  },
  render(h) {
    return (
      <div class='page-top-wrap'>
        <div class='info'>
          <div ref='container' class={'title-wrap' + (this.editable ? ' is-editable' : '')}>
            {
              !this.editing
                ? <h2 class='page-title' onClick={this.handleToEdit}>{ this.title }</h2>
                : ''
            }
            {
              this.editable && !this.editing
                ? <i class='iconfont icon-a-icon-sign5beifen1x' onClick={this.handleToEdit}></i>
                : ''
            }
            {
              this.editable && this.editing
                ? <input ref='editInput' class='edit-input' value={this.title} onKeyup={this.handleSubmit} />
                : ''
            }
          </div>
          <i class={this.STATUS[this.status].icon}></i>
          <span class='status'>{this.STATUS[this.status].name}</span>
        </div>
        <button class='save' onClick={this.handleSave}>{this.LP.lang_keyinfo_save}<i class='iconfont icon-a-icon-save1x'></i></button>
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
.page-top-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #e4e7ed;
  .info {
    display: flex;
    align-items: center;
    .title-wrap {
      padding-right: 24px;
      display: flex;
      align-items: center;
      &.is-editable {
        cursor: pointer;
      }
      .page-title {
        font-size: 24px;
        font-weight: bold;
        color: #303133;
        line-height: 32px;
      }
      .iconfont {
        font-size: 24px;
        padding-left: 11px;
        color: #909399;
      }
      .edit-input {
        font-size: 24px;
        font-weight: bold;
        color: #303133;
        line-height: 30px;
        border-radius: 4px;
        border: 1px solid @theme;
        padding: 0 20px;
        max-width: 230px;
      }
    }
    .iconfont {
      font-size: 24px;
    }
    .status {
      font-size: 14px;
      color: #333333;
      line-height: 17px;
    }
  }
  .save {
    appearance: none;
    border: 1px solid rgba(49, 155, 216, 0.36);
    padding: 7px 16px 7px 20px;
    font-size: 16px;
    color: white;
    line-height: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #319BD8;
    // background: transparent;
    cursor: pointer;
    &:hover{
      background-color: #33b5ae;
    }
    .iconfont {
      font-weight: normal;
      font-size: 24px;
      color: white;
      padding-left: 8px;
    }
  }
}
</style>
