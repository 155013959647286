<template>
  <div class="row-upload">
    <div class="inner">
      <!-- 上传按钮在左边 -->
      <template v-if="type === 'left'">
        <el-upload
          ref="uploader"
          class="uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :before-upload="onBeforeUpload"
          :on-progress="onProgress"
          :on-success="onSuccess"
          :on-error="onError"
        >
          <div class="upload-btn">
            <i class="iconfont icon-a-icon-upload1x"></i>
            <span class="text">{{ LP.lang_upload }}</span>
          </div>
        </el-upload>
        <p v-if="value" class="file-name ellipsis">{{ value[prop.name] }}</p>
        <div class="delete">
          <span
            class="iconfont icon-a-icon-delete1x delete-btn"
            @click="handleRowDelete"
          ></span>
        </div>
      </template>
      <!-- 上传按钮在右边 -->
      <template v-if="type === 'right'">
        <p v-if="value" class="file-name ellipsis right">{{ value[prop.name] }}</p>
        <el-upload
          ref="uploader"
          class="uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :before-upload="onBeforeUpload"
          :on-progress="onProgress"
          :on-success="onSuccess"
          :on-error="onError"
        >
          <div class="upload-btn">
            <i class="iconfont icon-a-icon-upload1x"></i>
            <span v-if="text" class="text">{{ LP.lang_upload }}</span>
            <slot v-else></slot>
          </div>
        </el-upload>
      </template>
    </div>
    <!-- 上传进度条 -->
    <transition name="fade">
      <div v-if="isUploading" class="progress-bar-wrap">
        <div class="progress-rail">
          <div class="progerss">
            <div class="progerss-bar" :style="{width: progressPercent}"></div>
          </div>
          <span class="percent">{{ progressTip }}</span>
        </div>
        <i class="iconfont icon-a-icon-delete1x delete-btn" @click="stopUpload"></i>
      </div>
    </transition>
  </div>
</template>

<script>
import emitter from '@/mixin/emitter.js'
import { _axios } from '@/plugins/axios.js'
export default {
  mixins: [emitter],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 双向绑定数据
    value: {
      type: Object,
      default: () => ({})
    },
    // 双向绑定文件名和下载地址字段名
    prop: {
      type: Object,
      default: () => ({
        name: 'file_name',
        url: 'download'
      })
    },
    // 布局类型 默认left上传按钮在左边，right上传按钮在右边
    type: {
      type: String,
      default: 'left'
    },
    // 按钮文本
    text: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isUploading: false,
      progress: 0,
      uploadFileName: '', // 暂存文件名，上传完成后返回给父级
      uploadUrl: _axios.defaults.baseURL + '/home/upload'
    }
  },
  computed: {
    // 上传进度文字
    progressPercent() {
      return this.progress + '%'
    },
    // 上传进度提示
    progressTip() {
      return this.progressPercent === '100%' ? `${this.LP.lang_processing}...` : this.progressPercent
    }
  },
  watch: {
    value: {
      handler(val) {
        // 双向绑定值变化时触发element校验事件
        this.dispatch('ElFormItem', 'el.form.blur', [])
      },
      deep: true
    }
  },
  methods: {
    // 上传前
    onBeforeUpload(file) {
      this.uploadFileName = file.name
      this.isUploading = true
      return true
    },
    // 上传进度
    onProgress(event) {
      this.progress = Math.floor(event.loaded / event.total * 100)
    },
    // 上传成功
    onSuccess(response) {
      if (response.status === '00') {
        this.$message.success('Success')
        setTimeout(() => {
          this.isUploading = false
          this.$nextTick(() => {
            this.$emit('change', {
              ...this.value,
              [this.prop.name]: this.uploadFileName,
              [this.prop.url]: response.data
            })
            this.handleResetAll()
          })
        }, 700)
      } else {
        this.$message.error(response.message)
        setTimeout(() => {
          this.isUploading = false
          this.handleResetAll()
        }, 700)
      }
    },
    // 上传失败
    onError(err) {
      if (err?.message) {
        this.$message.error(JSON.parse(err.message).message)
      } else {
        this.$message.error('Error')
      }
      setTimeout(() => {
        this.isUploading = false
        this.handleResetAll()
      }, 700)
    },
    // 停止上传
    stopUpload() {
      this.$refs.uploader.abort()
      this.isUploading = false
      this.$nextTick(() => {
        this.handleResetAll()
      })
    },
    // 删除行点击
    handleRowDelete() {
      this.$emit('delete')
    },
    // 重置数据
    handleResetAll() {
      this.progress = 0
      this.uploadFileName = ''
      this.$refs.uploader.clearFiles()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.row-upload {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 48px;
  width: 100%;
  line-height: 46px;
  border: 1px solid #DCDFE6;
  background: #ffffff;
  border-radius: 4px;
  padding-left: 16px;
  &:hover {
    border-color: @theme;
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .uploader {
      line-height: 46px;
      height: 46px;
    }
    .upload-btn {
      width: 100px;
      color: #2d74b7;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      flex-shrink: 0;
      &:hover {
        opacity: 0.8;
      }
      .iconfont {
        font-size: 24px;
      }
      .text {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        padding-left: 6px;
      }
    }
    .file-name {
      flex-grow: 1;
      &.right {
        padding-right: 16px;
      }
    }
    .delete {
      background: #fefafb;
      height: 100%;
      line-height: 46px;
      padding: 0 12px;
      border-left: 1px solid #DCDFE6;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      flex-shrink: 0;
      .delete-btn {
        display: block;
        font-size: 24px;
        line-height: 24px;
        color: #c8161d;
        cursor: pointer;
        .transition();
        &:hover {
          transform: rotate(180deg);
        }
      }
    }
  }
  .progress-bar-wrap {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-radius: 4px;
    cursor: default;
    .progress-rail {
      position: absolute;
      left: calc(50% - 50px);
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 350px;
      width: 92%;
      height: 16px;
      border-radius: 8px;
      background: #f5f5f5;
      .progerss {
        margin: 2px;
        .progerss-bar {
          height: 12px;
          border-radius: 6px;
          width: 0;
          background: @theme;
          transition: width 0.2s linear;
        }
      }
      .percent {
        white-space: nowrap;
        position: absolute;
        left: calc(100% + 20px);
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        text-shadow: 0px 0px 1px #272727;
      }
    }
    .delete-btn {
      display: block;
      color: #f5f5f5;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      line-height: 24px;
      cursor: pointer;
      .transition(0.4s);
      &:hover {
        color: #ffffff;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}
</style>
<style lang="less">
.el-form-item.is-error {
  .row-upload:not(.is-not-error) {
    border: 1px solid #F56C6C;
  }
}
</style>
