<template>
  <div class="cropper-upload" :class="className">
    <!-- 默认提示 -->
    <div v-if="!hasImg" class="placeholder" @click="selectImg">
      <img class="default-img" src="@/assets/img/myPage/defaultImg.png" alt="default upload image" />
      <p v-if="showHint" class="hint-wrap">
        <span v-if="!!hitFormats.length" class="hint-line">Formats: {{ hitFormats.join(', ') }}</span>
        <span v-if="!!hitSize.length" class="hint-line">Domension: {{ hitSize[0] }}*{{ hitSize[1] }}</span>
      </p>
    </div>
    <!-- 已上传的图片预览 -->
    <div v-else class="img-preview" @click.stop="handlePreview(true)">
      <img class="img" :src="value" alt="img" />
      <i class="iconfont icon-a-icon-delete1x delete-btn" @click.stop="deleteImage"></i>
    </div>
    <!-- 上传进度条 -->
    <transition name="fade">
      <div v-if="isUploading" class="progress-bar-wrap">
        <div class="progress-rail">
          <div class="progerss">
            <div class="progerss-bar" :style="{width: progressPercent}"></div>
          </div>
          <span class="percent">{{ progressTip }}</span>
        </div>
        <i class="iconfont icon-a-icon-delete1x delete-btn" @click="stopUpload"></i>
      </div>
    </transition>
    <!-- 裁剪文件选择 -->
    <input id="uploads" ref="cropperInput" type="file" class="hide" :accept="inputAccept" @change="addImageToCropper($event)" />
    <!-- 裁剪框 -->
    <el-dialog
      custom-class="cropper-dialog"
      :title="LP.lang_upload_file"
      width="92%"
      :visible.sync="uploadDialog"
      :before-close="handleDialogClose"
    >
      <div v-loading="!showOperation" class="cropper-wrap">
        <vueCropper
          ref="cropperInstance"
          :img="options.img"
          :output-size="options.size"
          :output-type="options.outputType"
          :info="true"
          :full="options.full"
          :can-move="options.canMove"
          :can-move-box="options.canMoveBox"
          :fixed-box="options.fixedBox"
          :original="options.original"
          :auto-crop="options.autoCrop"
          :auto-crop-width="options.autoCropWidth"
          :auto-crop-height="options.autoCropHeight"
          :center-box="options.centerBox"
          :high="options.high"
          :info-true="options.infoTrue"
          :enlarge="options.enlarge"
          @imgLoad="imgLoad"
          @cropMoving="cropMoving"
        />
      </div>
      <div v-if="showOperation" class="operation-bar">
        <el-tooltip
          v-for="(item, index) in operation"
          :key="index"
          effect="dark"
          :disabled="!item.name"
          :content="item.name"
          placement="bottom"
        >
          <span
            :class="item.className"
            @click="handleOperations(item.funcName, item.value)"
          ></span>
        </el-tooltip>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleDialogClose">{{ LP.lang_cancel }}</el-button>
        <el-button type="primary" @click="handleUpload">{{ LP.lang_upload }}</el-button>
      </span>
    </el-dialog>
    <!-- 大图预览 -->
    <el-dialog
      title="预览"
      custom-class="preview-dialog"
      :visible.sync="previewDialog"
      :before-close="() => handlePreview(false)"
    >
      <img class="preview" :src="value" alt="img" />
      <i class="iconfont icon-a-icon-delete1x delete-btn" @click.self="handlePreview(false)"></i>
    </el-dialog>
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'
import { upload } from '@/api/myPage'
import { accept } from '@/constant.js'
import cancelAxios from '@/mixin/cancelAxios.js'
import emitter from '@/mixin/emitter.js'

export default {
  components: {
    VueCropper
  },
  mixins: [cancelAxios, emitter],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 图片地址 双向绑定
    value: {
      type: String,
      default: ''
    },
    // 自定义类名
    className: {
      type: [String, Array, Object],
      default: ''
    },
    // 是否展示上传提示信息
    showHint: {
      type: Boolean,
      default: true
    },
    // 提示语 上传类型
    hitFormats: {
      type: Array,
      default: () => ['jpg', 'png']
    },
    // 提示语 长宽
    hitSize: {
      type: Array,
      default: () => [540, 320],
      validator: v => v.length === 2 || v.length === 0
    },
    // 裁剪框大小是否可以修改
    sizeCanChange: {
      type: Boolean,
      default: false
    },
    // 输出图片类型
    outputType: {
      type: String,
      default: 'png',
      validator: v => ['jpeg', 'png', 'webp'].includes(v)
    }
  },
  data() {
    return {
      uploadDialog: false,
      showOperation: false,
      option: {
        img: '',
        size: 1,
        full: false,
        canMove: true,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        centerBox: false,
        high: false,
        cropData: {},
        enlarge: 1,
        mode: 'contain',
        maxImgSize: 'max'
      },
      // 操作按钮
      operation: Object.freeze([
        { name: 'Zoom In', className: 'el-icon-zoom-in', funcName: 'changeScale', value: [1] },
        { name: 'Zoom Out', className: 'el-icon-zoom-out', funcName: 'changeScale', value: [-1] },
        { name: 'Rotate Right', className: 'el-icon-refresh-right', funcName: 'changeRotate', value: ['right'] },
        { name: 'Rotate Left', className: 'el-icon-refresh-left', funcName: 'changeRotate', value: ['left'] },
        { name: 'Reset', className: 'el-icon-refresh', funcName: 'refresh', value: [] }
      ]),
      isUploading: false,
      progress: 0,
      previewDialog: false
    }
  },
  computed: {
    // 是否已有图上传了
    hasImg() {
      return !!this.value
    },
    // 裁剪框插件配置
    options() {
      return {
        ...this.option,
        autoCropWidth: this.hitSize[0],
        autoCropHeight: this.hitSize[1],
        fixedBox: !this.sizeCanChange && this.hitSize.length === 2,
        outputType: this.outputType
      }
    },
    // 上传进度百分比
    progressPercent() {
      return this.progress + '%'
    },
    // 上传进度提示
    progressTip() {
      return this.progressPercent === '100%' ? `${this.lang_processing}...` : this.progressPercent
    },
    // input框accept属性
    inputAccept() {
      if (!this.hitFormats.length) {
        return '*'
      } else {
        let _t = this.hitFormats.reduce((prev, cur) => {
          if (Object.hasOwnProperty.call(accept, cur)) {
            prev = [...prev, ...accept[cur]]
          } else {
            prev = [...prev, '.' + cur]
          }
          return prev
        }, [])
        _t = (Array.from(new Set(_t))).join(', ')
        return _t
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        // 双向绑定值变化时触发element校验事件
        this.dispatch('ElFormItem', 'el.form.blur', [])
      },
      deep: true
    }
  },
  methods: {
    // 点击选择图片
    selectImg() {
      if (!this.hasImg) {
        this.$refs.cropperInput.click()
      }
    },
    // 将用户选择的图片添加至裁切框
    addImageToCropper(e) {
      const file = e.target.files[0]
      const reg = new RegExp(`(${this.inputAccept.split(', ').join('|')})`, 'ig')
      if (!reg.test(file.type)) {
        this.$message.error(this.LP.lang_file_type_err)
        this.handleResetAll()
        return false
      }
      var reader = new FileReader()
      reader.onload = e => {
        let data
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
        this.$refs.cropperInput.value = ''
      }
      reader.readAsArrayBuffer(file)
      this.uploadDialog = true
    },
    // 图片读取完成
    imgLoad(result) {
      this.showOperation = (result === 'success')
    },
    // 移动
    cropMoving(data) {
      this.option.cropData = data
    },
    // 操作按钮封装
    handleOperations(funcName, values) {
      if (this[funcName]) {
        this[funcName].apply(null, values)
      }
    },
    // 缩放
    changeScale(num) {
      num = num || 1
      this.$refs.cropperInstance.changeScale(num)
    },
    // 旋转
    changeRotate(type) {
      if (type === 'right') {
        this.$refs.cropperInstance.rotateRight()
      } else {
        this.$refs.cropperInstance.rotateLeft()
      }
    },
    // 重置
    refresh() {
      this.showOperation = false
      this.$refs.cropperInstance.refresh()
    },
    // 裁切框弹窗关闭
    handleDialogClose() {
      this.uploadDialog = false
      this.handleResetAll()
    },
    // 文件上传
    handleUpload() {
      this.$refs.cropperInstance.getCropBlob(data => {
        var formData = new FormData()
        formData.append('file', new File([data], `${(new Date()).getTime()}.png`))
        this.uploadDialog = false
        this.isUploading = true
        upload(formData, this.OnProgress, this.cancelToken()).then(res => {
          setTimeout(() => {
            this.isUploading = false
            this.$nextTick(() => {
              this.handleResetAll()
            })
          }, 700)
          this.$message.success('success')
          this.$emit('change', res.data)
        }).catch(err => {
          if (err?.response?.data) {
            this.$message.error(err.response.data.message)
          } else if (err.type !== 'cancel') {
            this.$message.error('Error')
          }
          this.isUploading = false
        })
      })
    },
    // 上传进度监听
    OnProgress(event) {
      this.progress = Math.floor(event.loaded / event.total * 100)
    },
    // 停止上传
    stopUpload() {
      this.cancelRequest()
    },
    // 删除图片
    deleteImage() {
      this.$emit('change', '')
      this.handleResetAll()
    },
    // 预览弹窗打开关闭
    handlePreview(t) {
      this.previewDialog = t
    },
    // 清空所有数据
    handleResetAll() {
      this.isUploading = false
      this.progress = 0
      this.showOperation = false
      this.$refs.cropperInput.value = ''
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.cropper-upload {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  min-height: 120px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px dashed #E4E7ED;
  .transition(0.4s);
  &:hover {
    border-color: @theme;
    .placeholder {
      .hint-wrap {
        .hint-line {
          color: @theme;
        }
      }
    }
  }
  .placeholder {
    text-align: center;
    width: 100%;
    padding: 22px;
    cursor: pointer;
    .default-img {
      height: 36px;
      width: 36px;
    }
    .hint-wrap {
      margin-top: 2px;
      .hint-line {
        display: block;
        font-size: 14px;
        color: #979797;
        line-height: 19px;
        .transition(0.4s);
      }
    }
  }
  .img-preview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80px;
    width: 135px;
    background: #f4f4f5;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .img {
      max-width: 100%;
      max-height: 100%;
    }
    .delete-btn {
      position: absolute;
      right: -12px;
      top: -12px;
      color: #c8161d;
      font-size: 24px;
      line-height: 24px;
      .transition();
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
  .hide {
    display: none;
  }
  /deep/ .cropper-dialog {
    .el-dialog__body {
      .cropper-wrap {
        height: 500px;
      }
      .operation-bar {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 20px;
          font-weight: 600;
          color: @theme;
          padding: 3px 10px;
          border-radius: 3px;
          text-align: center;
          line-height: 26px;
          &:hover {
            background: #f5f5f5;
          }
        }
      }
    }
  }

  .progress-bar-wrap {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-radius: 4px;
    cursor: default;
    .progress-rail {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 350px;
      width: 92%;
      height: 16px;
      border-radius: 8px;
      background: #f5f5f5;
      .progerss {
        margin: 2px;
        .progerss-bar {
          height: 12px;
          border-radius: 6px;
          width: 0;
          background: @theme;
          transition: width 0.2s linear;
        }
      }
      .percent {
        position: absolute;
        left: 50%;
        top: calc(50% + 10px);
        transform: translateX(-50%);
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        text-shadow: 0px 0px 1px #272727;
      }
    }
    .delete-btn {
      display: block;
      color: #f5f5f5;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 24px;
      line-height: 24px;
      cursor: pointer;
      .transition(0.4s);
      &:hover {
        color: #ffffff;
        transform: rotate(180deg);
      }
    }
  }
  /deep/ .preview-dialog {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    background: transparent;
    border: none;
    box-shadow: none;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      height: 100%;
      background: transparent;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      .preview {
        // width: 100%;
        max-width: 100%;
      }
      .delete-btn {
        position: absolute;
        top: 60px;
        right: 60px;
        font-size: 50px;
        color: #ffffff;
        cursor: pointer;
        .transition(0.4s);
        &:hover {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-form-item.is-error {
  .cropper-upload:not(.is-not-error) {
    border: 1px solid #F56C6C;
  }
}
</style>
